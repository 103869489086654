import './Searching.css';

function Searching() {
  return (
    <div className="loadingio-spinner-magnify-swk0sg3vnn">
      <div className="ldio-ch3atfikf09">
        <div>
          <div>
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Searching;

import styled from 'styled-components';

var Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props['marginTop'] || '10vh'};
  margin-left: 5px;
  margin-right: 5px;
`;

var AlertMessage = styled.p`
  color: ${props =>
    props.type === 'success'
      ? 'rgba(39,179,39,0.88)'
      : 'rgba(224, 34, 34, 0.88)'};
  font-size: 22px;
  background-color: ${props =>
    props.type === 'success' ? '#eaffea' : '#ffeaea'};
  padding: 5px 12px;
  border-radius: 5px;
  font-family: 'Rokkitt', serif;
`;

/**
 * @param message Alert message, this should be string
 * @param marginTop margin-top value, default is `10vh`
 * @param type Alert type, can be `success` or `error`, default is `success`
 * @return {JSX.Element}
 */
function Alert({ message, marginTop, type = 'success' }) {
  return (
    <Wrapper marginTop={marginTop} type={type}>
      <AlertMessage type={type}>{message}</AlertMessage>
    </Wrapper>
  );
}

export default Alert;

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import Modal from '../components/Modal';
import { MonthPicker } from '../components/MonthPicker';
import { addThousandSeparator } from '../helpers';
import ReportingService from '../services/reporting';
import { MONTHS } from '../constants';

var Title = styled.h1`
  font-family: 'Reem Kufi', sans-serif;
  text-align: center;
  margin-top: 15px;
  font-size: 22px;
  color: #e28282;
  text-shadow: 0 2px 2px #cdcdcd;
`;

var Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 450px;
  padding: 10px;
  align-items: center;
  margin: 40px auto;
`;

var SaleBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  border-radius: 15px;
  padding: 10px 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${prop => prop.backgroundColor};
`;

var Order = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;

  p.label {
    font-size: 24px;
    font-family: 'Inder', sans-serif;
    color: ${prop => prop.color || 'rgba(0, 0, 0, 0.5)'};
    text-shadow: 0 3px 3px #cdcdcd;
  }

  p.value {
    font-size: 27px;
    font-family: 'McLaren', cursive;
    color: rgba(0, 0, 0, 0.65);
  }
`;

var Amount = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;

  p.label {
    font-size: 24px;
    font-family: 'Inder', sans-serif;
    color: ${prop => prop.color || 'rgba(0, 0, 0, 0.5)'};
    text-shadow: 0 3px 3px #cdcdcd;
  }

  p.value {
    font-size: 27px;
    font-family: 'McLaren', cursive;
    color: rgba(0, 0, 0, 0.65);
  }
`;

var Category = styled.p`
  font-size: 24px;
  font-family: 'Inder', sans-serif;
  text-shadow: 0 3px 3px #cdcdcd;
  color: ${prop => prop.categoryColor};
  text-align: center;
`;

var ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -20px;
`;

var MonthPickerButton = styled.button`
  padding: 7px 30px;
  font-size: 20px;
  border-radius: 50px;
  font-family: 'Inder', sans-serif;
  border: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: rgba(00, 00, 00, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: rgba(234, 226, 183, 0.2);

  &:hover {
    background-color: rgba(234, 226, 183, 0.5);
  }

  ion-icon {
    margin-top: 3px;
  }
`;

var Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    font-size: 17px;
    font-family: 'Inder', sans-serif;
    color: rgba(0, 0, 0, 0.5);

    .date {
      color: rgba(100, 100, 100, 0.9);
    }
  }
`;

var colorTheme = [
  {
    categoryColor: 'rgba(156, 21, 177, 0.65)',
    borderColor: '#000000',
    backgroundColor: 'rgba(239, 238, 244, 0.65)',
  },
  {
    categoryColor: 'rgba(3, 94, 122, 0.5)',
    borderColor: '#000000',
    backgroundColor: 'rgba(224, 238, 248, 0.35)',
  },
  {
    categoryColor: 'rgba(77, 125, 7, 0.5)',
    borderColor: '#000000',
    backgroundColor: 'rgba(246, 255, 241, 0.5)',
  },
  {
    categoryColor: 'rgba(129, 87, 8, 0.74)',
    borderColor: '#EAE2B7',
    backgroundColor: 'rgba(234, 226, 183, 0.5)',
  },
  {
    categoryColor: 'rgba(222,26,26,0.5)',
    borderColor: '#EAE2B7',
    backgroundColor: 'rgba(222,26,26,0.04)',
  },
];

function SaleAnalysis() {
  var [filter, setFilter] = useState({ year: 2023, month: 0 });

  var [sales, setSales] = useState(null);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');
  var [isDatePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    setError('');
    setLoading(true);

    ReportingService.getSaleAnalysis(filter.year, filter.month + 1)
      .then(sales => sales.sort((sale1, sale2) => sale1.sale - sale2.sale))
      .then(sales => {
        sales.forEach(sale => {
          const areaMapping = {
            PRE_ORDER: 'Pre Order',
            YANGON: 'Yangon',
            ROYAL: 'Royal',
            MANDALAY: 'Mandalay',
            SUMMARY: 'Summary',
          };

          sale['area'] = areaMapping[sale['area']];
        });

        return sales;
      })
      .then(setSales)
      .catch(error => {
        setError(error.message);
      })
      .finally(() => setLoading(false));
  }, [filter]);

  return (
    <>
      <Title>Sale Analysis</Title>

      {loading && <Loader marginTop={'30px'} />}

      {!loading && error && (
        <Alert type={'error'} message={error} marginTop={'30px'} />
      )}

      {!loading && sales && (
        <Container>
          <Headline>
            <p>
              Total sale of
              <span className="date">
                {` ${MONTHS[filter.month]} ${filter.year}`}
              </span>
            </p>

            <MonthPickerButton
              onClick={() => {
                setDatePickerOpen(true);
              }}>
              date <ion-icon name="chevron-down-outline" />
            </MonthPickerButton>
          </Headline>

          {sales.map((sale, index) => {
            const theme = colorTheme[index % colorTheme.length];

            return (
              <SaleBox {...theme} key={sale.area}>
                <Category {...theme}>{sale.area}</Category>

                <ContentWrapper>
                  <Order {...theme}>
                    <p className="label">orders</p>
                    <p className="value">{sale.order_count}</p>
                  </Order>

                  <Amount {...theme}>
                    <p className="label">amount</p>
                    <p className="value">{addThousandSeparator(sale.sale)}</p>
                  </Amount>
                </ContentWrapper>
              </SaleBox>
            );
          })}
        </Container>
      )}

      {isDatePickerOpen && (
        <Modal open={isDatePickerOpen} onClose={() => setDatePickerOpen(false)}>
          <MonthPicker
            onChange={(year, month) => {
              setDatePickerOpen(false);
              setFilter({ year, month });
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default SaleAnalysis;

import axios from 'axios';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY } from '../constants';

const API_URL = process.env.REACT_APP_API_URL;

export function login({ email, password }) {
  return axios
    .post(API_URL + '/users/sign-in', { email, password })
    .then(response => response.data)
    .then(data => {
      // we need to clear
      localStorage.clear();

      var { user, token } = data;

      if (user) {
        localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
      }

      if (token) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
      }

      return user;
    });
}

export function logout() {
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
}

export function isAuthenticated() {
  return (
    localStorage.getItem(LOCAL_STORAGE_USER_KEY) &&
    localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
  );
}

import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

var Footer = styled.footer`
  background-color: #e5626a;
  position: fixed;
  width: 100%;
  min-height: 45px;
  bottom: 0;
  display: flex;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  justify-content: space-evenly;
`;

var NavButton = styled.div`
  background-color: #ffffff;

  width: 75px;
  height: 60px;
  border: 1px solid #ffeaea;
  border-radius: 15px;
  transform: translateY(-20px);
  box-shadow: 0 4px 4px 0 #00000040;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Reem Kufi', sans-serif;
  font-weight: 400;
  font-size: 17px;

  &.active {
    background-color: #ffeaea;
  }
`;

var OrderIconImg = styled.img`
  margin-top: 2px;
  width: 29px;
  height: 31px;
  transform: translateY(3px);
`;

var StoreIconImg = styled.img`
  width: 50px;
  height: 45px;
  transform: translateY(9px);
  margin-top: -8px;
`;

var StocksIconImg = styled.img`
  width: 46px;
  height: 32px;
  transform: translateY(4px);
`;

const PAGES = {
  ORDERS: '/orders',
  STORE: '/store',
  STOCKS: '/stocks',
};

function AppFooter() {
  var navigate = useNavigate();
  var location = useLocation();

  var goTo = path => () => navigate(path);
  var getClassName = path => (location.pathname === path ? 'active' : '');

  return (
    <Footer>
      <NavButton
        onClick={goTo(PAGES.ORDERS)}
        className={getClassName(PAGES.ORDERS)}>
        <OrderIconImg src={'/images/orders.png'} alt={'Orders Icon'} />
        <span>orders</span>
      </NavButton>
      <NavButton
        onClick={goTo(PAGES.STORE)}
        className={getClassName(PAGES.STORE)}>
        <StoreIconImg src={'/images/store.png'} alt={'Store Icon'} />
        <span>store</span>
      </NavButton>
      <NavButton
        onClick={goTo(PAGES.STOCKS)}
        className={getClassName(PAGES.STOCKS)}>
        <StocksIconImg src={'/images/stocks.png'} alt={'Stocks Icon'} />
        <span>stocks</span>
      </NavButton>
    </Footer>
  );
}

export default AppFooter;

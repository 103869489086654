import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

/**
 * tablet - max 768px
 * mobile large - max 425px
 * mobile small - max 320px
 */

var Wrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 250px;

  @media (max-width: 768px) {
    width: 30vw;
  }

  @media (max-width: 425px) {
    width: 45vw;
  }

  @media (max-width: 320px) {
    width: 90vw;
  }
`;

var Image = styled.div`
  border-radius: 10px;
  background-image: url(${props => props.src});
  background-size: cover;
  width: 100%;
  height: 250px;
  border: ${props => (props.showWarning ? '2px solid #e5626a' : 'none')};

  @media (max-width: 768px) {
    height: 30vw;
  }

  @media (max-width: 425px) {
    height: 45vw;
  }

  @media (max-width: 320px) {
    height: 90vw;
  }
`;

var Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 3px;
`;

var Name = styled.p`
  font-weight: bold;
  font-family: 'Rokkitt', serif;
`;

var Price = styled.p`
  font-family: 'Rokkitt', serif;
  color: #8d8989;
`;

var IconBtn = styled.div`
  font-size: 30px;
  color: #818181;
  display: flex;
  align-items: center;
`;

function StockItem({ _id, image, name, price, quantity }) {
  var navigate = useNavigate();
  var goToDetailsPage = _id => () => navigate(`/stocks/${_id}`);

  return (
    <Wrapper>
      <Image src={image} showWarning={quantity < 3} />
      <Description>
        <div>
          <Name>{name}</Name>
          <Price>{price} MMK</Price>
        </div>
        <IconBtn onClick={goToDetailsPage(_id)}>
          <ion-icon name="information-circle-outline" />
        </IconBtn>
      </Description>
    </Wrapper>
  );
}

export default StockItem;

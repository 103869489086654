import styled from 'styled-components';

var Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

var Container = styled.div`
  margin-top: 30px;
  display: flex;
  overflow-x: auto;
  max-width: 75%;
`;

var Item = styled.p`
  border: 1px solid #efb3b3;
  padding: 1px 12px;
  margin-right: 7px;
  border-radius: 4px;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 18px;
  font-weight: normal;

  ${props => props.currentPage && 'background-color: #de7070;color: white;'}
`;

var range = (start, end) => {
  var ret = [];
  for (; start < end; ) ret.push(start++);
  return ret;
};

function Pagination({
  totalItems = 0,
  itemsPerPage = 5,
  currentPage = 1,
  onPageChange = v => v,
}) {
  var pages = range(1, Math.ceil(totalItems / itemsPerPage) + 1);

  return (
    <Wrapper>
      {pages.length > 1 && (
        <Container>
          {pages.map((page, idx) => (
            <Item
              key={idx}
              currentPage={page === currentPage}
              onClick={() => {
                if (page !== currentPage) onPageChange(page);
              }}>
              {page}
            </Item>
          ))}
        </Container>
      )}
    </Wrapper>
  );
}

export default Pagination;

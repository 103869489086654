import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import Loader from './Loader';
import Alert from './Alert';
import ProductService from '../services/stocks';

var Container = styled.div`
  h2 {
    padding: 10px 7px;
    text-align: center;
    color: #db7474;
    font-family: 'Reem Kufi', sans-serif;
    font-size: 22px;
    margin-top: 5px;
  }
`;

var ProductItem = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-family: 'Reem Kufi', sans-serif;
  padding: 10px;
  align-items: center;

  ul {
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    li {
      margin-top: 5px;
      margin-right: 10px;
      border: 1px solid #e79595;
      color: #e79595;
      padding: 0 8px;
      border-radius: 5px;
    }

    li.active {
      background-color: #e79595;
      color: white;
    }
  }
  
  img {
    width: 80px;
    height: 80px;
    border-radius: 7px;
  }
`;

var Actions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;

  button {
    padding: 7px 12px;
    border: none;
    font-family: 'Reem Kufi', sans-serif;
    font-size: 16px;
    border-radius: 9px;
    box-shadow: 0 3px 3px 0 #cdcdcd;
  }

  button:nth-child(1) {
    color: #707070;
  }

  button:nth-child(2) {
    color: white;
    background-color: #f18484;
  }
`;

function merge(selected, products) {
  return Object.values(selected)
    .map(item => {
      var product = products[item.product_id];

      if (!product) return null;

      var stk = product.in_stock.find(p => p.size === item.size);

      if (stk) {
        return {
          product_id: product._id,
          name: product.name,
          price: product.price,
          image: product.image,
          size: stk.size,
          quantity: 1,
          selected_quantity: 1,
          available_quantity: stk.quantity,
        };
      }

      return null;
    })
    .filter(Boolean);
}

function ChooseProducts({
  searchTerm = '',
  onChooseBoxClose,
  existingItems = [],
}) {
  var [loading, setLoading] = useState(false);
  var [products, setProducts] = useState({});
  var [error, setError] = useState('');
  var [selected, setSelected] = useState([]);

  useEffect(() => {
    setLoading(true);

    ProductService.getProducts({ all: true, search: searchTerm, qty_gt: 0 })
      .then(
        R.compose(
          setProducts,
          R.reduce((acc, x) => ({ ...acc, [x._id]: x }), {}),
          R.filter(p => p.in_stock.length > 0),
          R.map(p => {
            var matches = existingItems.filter(t => t.product_id === p._id);

            if (matches.length === 0) return p;

            var existingSizes = matches.map(x => x.size);

            return R.evolve(
              { in_stock: R.filter(stk => !existingSizes.includes(stk.size)) },
              p
            );
          }),
          R.prop('data')
        )
      )
      .catch(() => {
        setError("Something's wrong while searching products!");
      })
      .finally(() => setLoading(false));
  }, [searchTerm, existingItems]);

  var onChooseClick = R.curry((product_id, size, event) => {
    event.preventDefault();
    event.stopPropagation();

    var exist = selected[product_id + size];

    if (exist != null) {
      setSelected(R.dissoc(product_id + size, selected));
    } else {
      setSelected(R.assoc(product_id + size, { product_id, size }, selected));
    }
  });

  var ps = Object.values(products);

  return (
    <Container>
      {loading ? (
        <Loader marginTop={'25px'} />
      ) : (
        <>
          <h2>Choose Products</h2>

          {error && <Alert type={'error'} message={error} marginTop={'20px'} />}

          {ps.length === 0 ? (
            <Alert
              type={'success'}
              message={'No products found. Please try another keyword!'}
              marginTop={'20px'}
            />
          ) : (
            ps.map(product => (
              <ProductItem key={product._id}>
                <div>
                  <p>{product.name}</p>

                  <ul>
                    {product.in_stock.map(stk => (
                      <li
                        className={
                          selected[product._id + stk.size] ? 'active' : ''
                        }
                        key={stk._id}
                        onClick={onChooseClick(product._id, stk.size)}>
                        {stk.size} - {stk.quantity}
                      </li>
                    ))}
                  </ul>
                </div>

                <img src={product.image} alt="Product" />
              </ProductItem>
            ))
          )}

          <Actions>
            <button onClick={() => onChooseBoxClose({ type: 'CANCEL' })}>
              Cancel
            </button>
            <button
              onClick={() =>
                onChooseBoxClose({
                  type: 'CONFIRM',
                  data: merge(selected, products),
                })
              }>
              Confirm
            </button>
          </Actions>
        </>
      )}
    </Container>
  );
}

export default ChooseProducts;

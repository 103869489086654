import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Orders from './pages/Orders';
import NewOrders from './pages/NewOrders';
import CompletedOrders from './pages/CompletedOrders';
import OrderDetail from './pages/OrderDetail';
import OrderEdit from './pages/OrderEdit';
import Store from './pages/Store';
import Stocks from './pages/Stocks';
import StockDetail from './pages/StockDetail';
import StockForm from './pages/StockForm';
import StockEditForm from './pages/StockEditForm';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Checkout from './pages/Checkout';
import Invoice from './pages/Invoice';
import Reports from './pages/Reports';
import SaleAnalysis from './pages/SaleAnalysis';
import ProductAnalysis from './pages/ProductAnalysis';

import GlobalStyles from './components/GlobalStyles';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';

import { CartProvider } from './context/CartContext';
import './index.css';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CartProvider>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route element={<Layout />}>
                <Route path={'/'} element={<Orders />} />
                <Route path={'/orders'} element={<Orders />} />
                <Route path={'/orders/new'} element={<NewOrders />} />
                <Route
                  path={'/orders/completed'}
                  element={<CompletedOrders />}
                />
                <Route
                  path={'/orders/:_id/details'}
                  element={<OrderDetail />}
                />
                <Route path={'/orders/:_id/invoice'} element={<Invoice />} />
                <Route path={'/orders/:_id/edit'} element={<OrderEdit />} />
                <Route path={'/store'} element={<Store />} />
                <Route path={'/stocks'} element={<Stocks />} />
                <Route path={'/stocks/new'} element={<StockForm />} />
                <Route path={'/stocks/:_id/edit'} element={<StockEditForm />} />
                <Route path={'/stocks/:_id'} element={<StockDetail />} />
                <Route path={'/checkout'} element={<Checkout />} />
                <Route path={'/reports'} element={<Reports />} />
                <Route path={'/sale-analysis'} element={<SaleAnalysis />} />
                <Route
                  path={'/product-analysis'}
                  element={<ProductAnalysis />}
                />
              </Route>
            </Route>
            <Route path={'*'} element={<NotFound />} />
          </Routes>
        </Router>
      </CartProvider>
    </QueryClientProvider>
  );
}

export default App;

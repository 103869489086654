import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';

import ProductService from '../services/stocks';
import { CartContext } from '../context/CartContext';

var StyledHeader = styled.div`
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 425px) {
    padding: 0 5px;
  }

  @media (max-width: 320px) {
    padding: 0 5px;
  }
`;

var SearchContainer = styled.div`
  display: flex;
  font-family: 'Reem Kufi', sans-serif;

  input {
    padding: 9px 9px;
    border: 2px solid #e79b9b;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  button {
    font-size: 20px;
    background-color: white;
    border: 2px solid #e79b9b;
    color: #de7070;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;

    ion-icon {
      font-weight: bold;
      margin-top: 3px;
    }

    :hover {
      background-color: #e79b9b;
      color: white;
    }
  }
`;

var CheckoutBtn = styled.div`
  position: relative;
  font-family: 'Reem Kufi', sans-serif;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: white;
  color: #e77777;

  ion-icon {
    margin-left: 3px;
    font-size: 25px;
  }

  &:hover {
    background-color: #ffeaea;
  }

  span {
    padding: 0 5px;
    border-radius: 20px;
    background-color: #e77777;
    font-size: 13px;
    position: relative;
    bottom: -10px;
    right: 5px;
    color: white;
  }
`;

var ItemsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

var ItemContainer = styled.div`
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 20px;
  width: 200px;

  @media (max-width: 768px) {
    width: 25vw;
  }

  @media (max-width: 425px) {
    width: 25vw;
  }

  @media (max-width: 320px) {
    width: 45vw;
  }

  p.product-name {
    font-family: 'Rokkitt', serif;
    margin-top: 5px;
  }
`;

var ItemImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: ${props => `url('${props.imageUrl}')`};
  background-size: cover;
  margin-right: 10px;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 25vw;
  }

  @media (max-width: 425px) {
    height: 25vw;
  }

  @media (max-width: 320px) {
    height: 45vw;
  }
`;

var ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 6px;
    height: 5px;
    background-color: ${props => (props.isSelected ? '#de7070' : 'darkgrey')};
    margin-right: 3px;
    border-radius: 100%;
  }
`;

var ChooseItemContainer = styled.div`
  font-family: 'Reem Kufi', sans-serif;

  h2 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
    color: #de7070;
    text-shadow: 0 4px 4px rgba(64, 64, 64, 0.25);
  }

  ul {
    list-style: none;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  li {
    margin-top: 10px;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #efb3b3;
  }

  li.active {
    background-color: #efb3b3;
    color: white;
  }

  div.actions {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    > button {
      padding: 4px 15px;
      font-family: 'Reem Kufi', sans-serif;
      font-size: 14px;
      border-radius: 10px;
      box-shadow: 0 3px 3px 0 #cdcdcd;
    }

    > button:nth-child(1) {
      margin-right: 10px;
      border: 1px solid #cdcdcd;
      background-color: floralwhite;
    }

    > button:nth-child(2) {
      border: 1px solid #efb3b3;
      background-color: #de7070;
      color: white;
    }
  }
`;

const ITEMS_PER_PAGE = 15;

function Item({ _id, name, image, in_stock }) {
  var { cartItemKeys, product_ids, addItem, removeItem } =
    useContext(CartContext);
  var [modalOpen, setModalOpen] = useState(false);

  var isSelectedItem = product_ids.some(productId => productId === _id);

  in_stock = in_stock.map(stk => {
    var key = `${_id}_${stk.size}`;
    var active = cartItemKeys.includes(key);
    return { ...stk, active };
  });

  function onClickHandler() {
    setModalOpen(true);
  }

  function onModalClose(type) {
    setModalOpen(false);

    if (type === 'CANCEL') {
      in_stock
        .filter(stk => stk.active)
        .map(stk => stk.size)
        .forEach(size => removeItem({ product_id: _id, size }));
    }
  }

  return (
    <>
      <ItemContainer onClick={onClickHandler}>
        <ItemImage imageUrl={image} />
        <ItemInfo isSelected={isSelectedItem}>
          <p className={'product-name'}>{name}</p>
          <div />
        </ItemInfo>
      </ItemContainer>
      <Modal open={modalOpen} onClose={onModalClose}>
        <ChooseItemContainer>
          <h2>{name}</h2>

          <ul>
            {in_stock.map(stk => {
              return (
                <li
                  className={stk.active ? 'active' : ''}
                  key={stk._id}
                  onClick={() =>
                    stk.active
                      ? removeItem({ product_id: _id, size: stk.size })
                      : addItem({ product_id: _id, size: stk.size })
                  }>
                  {stk.size}
                </li>
              );
            })}
          </ul>

          <div className={'actions'}>
            <button onClick={() => onModalClose('CANCEL')}>Cancel</button>
            <button onClick={() => onModalClose('CONFIRM')}>Confirm</button>
          </div>
        </ChooseItemContainer>
      </Modal>
    </>
  );
}

function Store() {
  var [searchTerm, setSearchTerm] = useState('');
  var [currentPage, setCurrentPage] = useState(1);
  var [totalCount, setTotalCount] = useState(0);
  var [products, setProducts] = useState([]);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');
  var { cartItemsCount } = useContext(CartContext);
  var searchInputRef = useRef();

  var skip = (currentPage - 1) * ITEMS_PER_PAGE;

  useEffect(() => {
    setLoading(true);
    ProductService.getProducts({
      limit: ITEMS_PER_PAGE,
      skip,
      qty_gt: 0,
      search: searchTerm,
    })
      .then(response => {
        setProducts(response.data);
        setTotalCount(response.count);
      })
      .catch(() => {
        setError("Something's wrong while loading products!");
      })
      .finally(() => setLoading(false));
  }, [skip, searchTerm]);

  var navigate = useNavigate();

  return (
    <>
      <StyledHeader>
        <SearchContainer>
          <input
            ref={searchInputRef}
            type="text"
            placeholder={'Search product name...'}
          />
          <button onClick={() => setSearchTerm(searchInputRef.current.value)}>
            <ion-icon name="search-outline" />
          </button>
        </SearchContainer>
        <CheckoutBtn onClick={() => navigate('/checkout')}>
          <ion-icon name="bag-handle-outline" />
          <span>{cartItemsCount}</span>
        </CheckoutBtn>
      </StyledHeader>

      {loading && <Loader marginTop={'50px'} />}

      {!loading && error && <Alert type={'error'} message={error} />}

      {!loading &&
        (products.length === 0 ? (
          <Alert
            type={'success'}
            message={
              'No items to show right now. Please add more products in stock and come back. Thanks!'
            }
          />
        ) : (
          <ItemsWrapper>
            {products.map(product => (
              <Item key={product._id} {...product} />
            ))}
          </ItemsWrapper>
        ))}

      <Pagination
        totalItems={totalCount}
        currentPage={currentPage}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={setCurrentPage}
      />
    </>
  );
}

export default Store;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addThousandSeparator } from '../helpers';
import styled from 'styled-components';
import * as moment from 'moment';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import OrderService from '../services/order';

var Container = styled.div`
  color: #4f4f4f;
  border: 1px solid #cdcdcd;
  margin: 25px auto auto;
  padding: 5px;
  font-family: 'Roboto Mono', monospace;
  max-width: 500px;

  @media (max-width: 768px) {
    width: 80vw;
  }

  @media (max-width: 425px) {
    width: 90vw;
  }

  @media (max-width: 320px) {
    width: 95vw;
  }

  h2 {
    text-align: center;
    font-size: 19px;
    font-weight: normal;
    line-height: 0.9;
  }

  p.sub-title {
    text-align: center;
    margin-top: 7px;
  }

  div.order-info {
    border-top: 2px dashed #cdcdcd;
    border-bottom: 2px dashed #cdcdcd;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    p {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      flex-wrap: wrap;
    }
  }

  table {
    margin-top: 5px;
    width: 100%;

    thead {
      th {
        font-weight: normal;
        font-size: 14px;
        width: 20%;
        padding-bottom: 4px;
        border-bottom: 2px dashed #cdcdcd;
      }

      th:nth-child(1) {
        width: 40%;
      }
    }

    tbody {
      td {
        font-weight: normal;
        font-size: 13px;
        width: 20%;
      }

      td:nth-child(1) {
        width: 40%;
      }

      td.center {
        text-align: center;
      }

      td.right {
        text-align: right;
      }

      tr:nth-last-child(5),
      tr:nth-last-child(3),
      tr:nth-last-child(1) {
        td {
          padding-top: 4px;
          border-top: 2px dashed #cdcdcd;
        }
      }

      tr:nth-last-child(1) {
        td {
          font-weight: bold;
          padding-bottom: 4px;
          border-bottom: 2px dashed #cdcdcd;
        }
      }
    }
  }

  p.notice {
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  div.divider {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    border-bottom: 2px dashed #cdcdcd;
  }
`;

function Invoice() {
  var { _id } = useParams();

  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');
  var [order, setOrder] = useState(null);

  useEffect(() => {
    setLoading(true);

    OrderService.getOrder(_id)
      .then(setOrder)
      .catch(() => {
        setError("Something's wrong while loading order!");
      })
      .finally(() => setLoading(false));
  }, [_id]);

  return (
    <div>
      {loading && <Loader marginTop={'30px'} />}
      {!loading && error && <Alert type={'error'} message={error} />}

      {!loading && order && (
        <Container>
          <h2>Jeany's Gems</h2>
          <p className={'sub-title'}>Order Invoice</p>

          <div className={'order-info'}>
            <p>
              <span>Order no:</span> <span>#{order.order_number}</span>
            </p>
            <p>
              <span>Date:</span>{' '}
              <span>{moment(order.createdAt).format('DD/MMM/YY h:mmA')}</span>
            </p>
            <p>
              <span>Name:</span> <span>{order.customer_name}</span>
            </p>
            <p>
              <span>Phone:</span> <span>{order.customer_phone}</span>
            </p>
            <p>
              <span>Address:</span> <span>{order.customer_address}</span>
            </p>
          </div>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Size</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              {order.items.map(item => (
                <tr key={item.product_id + item.size}>
                  <td>{item.name}</td>
                  <td className={'center'}>{item.size}</td>
                  <td className={'center'}>{item.quantity}</td>
                  <td className={'right'}>
                    {addThousandSeparator(item.quantity * item.price)}
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan={3}>Sub total</td>
                <td className={'right'}>
                  {addThousandSeparator(order.sub_total)}
                </td>
              </tr>

              <tr>
                <td colSpan={3}>Delivery fee</td>
                <td className={'right'}>
                {addThousandSeparator(order.shipping_fee)}
                </td>
              </tr>

              <tr>
                <td colSpan={3}>Total</td>
                <td className={'right'}>
                {addThousandSeparator(order.total)}
                </td>
              </tr>

              <tr>
                <td colSpan={3}>Paid</td>
                <td className={'right'}>
                  -{' '}
                  {addThousandSeparator(order.paid_amount)}
                </td>
              </tr>

              <tr>
                <td colSpan={3}>Remaining (COD)</td>
                <td className={'right'}>
                {addThousandSeparator(order.remaining)}
                </td>
              </tr>
            </tbody>
          </table>

          <p className={'notice'}>
            Thank you! Goods sold are not returnable or exchangeable!
          </p>
        </Container>
      )}
    </div>
  );
}

export default Invoice;

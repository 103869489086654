import S3 from 'react-aws-s3';

const config = {
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACESS_KEY,
};

const s3ProductClient = new S3({
  ...config,
  bucketName: process.env.REACT_APP_PRODUCT_BUCKET,
});

const s3OrderClient = new S3({
  ...config,
  bucketName: process.env.REACT_APP_ORDER_BUCKET,
});

function generateFilename() {
  var rand = Math.ceil(Math.random() * 1e5);
  var d = Date.now();
  return `${d}_${rand}`;
}

/**
 * @param file File
 * @param type Enum ['product', 'order']
 * @return {Promise<imageUrl>}
 */
export async function uploadImageToS3({ file, type = 'product' }) {
  var filename = generateFilename();

  if (type === 'product')
    var response = s3ProductClient.uploadFile(file, filename);
  else if (type === 'order')
    response = s3OrderClient.uploadFile(file, filename);

  var { location } = await response;

  return location;
}

import axios from '../axios';

const API_URL = process.env.REACT_APP_API_URL;

var ProductService = {
  getProducts(query) {
    var params = query.all
      ? {}
      : { limit: query.limit || 10, skip: query.skip || 0 };

    if (typeof query.qty_gt == 'number') {
      params = { ...params, qty_gt: query.qty_gt };
    }

    if (typeof query.search == 'string' && query.search.length > 0) {
      params = { ...params, search: query.search };
    }

    if (query.products_in && query.products_in.length > 0) {
      params = { ...params, products_in: query.products_in };
    }

    if (query.all) {
      params = { ...params, pagination: false };
    }

    return axios
      .get(API_URL.concat('/products'), { params })
      .then(response => response.data);
  },

  getProductById(_id) {
    return axios
      .get(`${API_URL}/products/${_id}`)
      .then(response => response.data);
  },

  createProduct(payload) {
    return axios
      .post(`${API_URL}/products`, { ...payload })
      .then(response => response.data);
  },

  removeProduct(_id) {
    return axios
      .delete(`${API_URL}/products/${_id}`)
      .then(response => response.data);
  },

  updateProduct(_id, update) {
    return axios
      .patch(`${API_URL}/products/${_id}`, { ...update })
      .then(response => response.data);
  },
};

export default ProductService;

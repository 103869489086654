import { useRef, useState } from 'react';
import styled from 'styled-components';

import Searching from './Searching';
import Alert from './Alert';
import Pagination from './Pagination';

import OrderService from '../services/order';
import { useNavigate } from 'react-router-dom';

var Container = styled.div`
  padding: 5px 15px;
  display: flex;
  flex-direction: column;

  div.header {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      flex-basis: 70%;
      padding: 4px 5px;
      border: 2px solid #e28c8c;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-family: 'Reem Kufi', sans-serif;
      font-size: 14px;
    }

    button {
      display: flex;
      align-items: center;
      flex-basis: 20%;
      border: 2px solid #e28c8c;
      background-color: #e28c8c;
      color: white;
      border-left: none;
      padding: 4px 10px;
      justify-content: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      font-family: 'Reem Kufi', sans-serif;
      font-size: 14px;

      &:disabled {
        border: 2px solid #eec5c5;
        background-color: #eec5c5;
      }

      ion-icon {
        margin-left: 3px;
      }
    }
  }

  div.search-info {
    margin-top: 10px;
    display: flex;
  }

  p.searched-keyword {
    margin-right: 10px;
  }

  p.searched-keyword,
  p.order-count {
    font-family: 'Reem Kufi', sans-serif;

    span {
      margin-left: 8px;
      background-color: #e28c8c;
      padding: 0 9px;
      color: white;
      border-radius: 5px;
    }
  }
`;

var OrderItemsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

var Order = styled.div`
  padding: 5px;
  margin-top: 15px;
  border: 1px solid #efefef;
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 #e2e2e2;
  font-family: 'Reem Kufi', sans-serif;

  div.name-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p.status {
    color: white;
    padding: 0 7px;
    border-radius: 4px;
  }

  .pending {
    background-color: #e28c8c;
  }

  .completed {
    background-color: #90e290;
  }

  p {
    color: #838383;
  }

  p.name,
  p.mobile {
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 4px;
    }
  }

  p.order-number {
    color: #e28c8c;
  }
`;

const ITEMS_PER_PAGE = 10;

function SearchOrders() {
  var [orderCount, setOrderCount] = useState(0);
  var [orders, setOrders] = useState([]);
  var [currentPage, setCurrentPage] = useState(1);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');
  var [searchTerm, setSearchTerm] = useState('');
  var lastSearchKeywordRef = useRef();
  var navigate = useNavigate();

  var onSearch = (keyword, currentPage) => {
    setLoading(true);

    OrderService.getOrders({
      search: keyword,
      limit: ITEMS_PER_PAGE,
      skip: (currentPage - 1) * ITEMS_PER_PAGE,
    })
      .then(response => {
        setOrders(response.data);
        setOrderCount(response.count);
      })
      .catch(() => {
        setError("Something's wrong while searching '" + keyword + "'");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <div className="header">
        <input
          value={searchTerm}
          onChange={event => {
            event.preventDefault();
            setSearchTerm(event.target.value);
          }}
          type="text"
          placeholder={'Order no, name, phone...'}
        />
        <button
          disabled={searchTerm.length < 3}
          onClick={event => {
            event.preventDefault();

            lastSearchKeywordRef.current = searchTerm;
            setCurrentPage(1);
            onSearch(searchTerm, 1);
          }}>
          Search <ion-icon name="search-outline" />
        </button>
      </div>

      {loading && <Searching />}
      {!loading && error && (
        <Alert marginTop={'25px'} type={'error'} message={error} />
      )}

      {!loading &&
        (orders.length === 0 ? (
          <Alert
            marginTop={'25px'}
            type={'success'}
            message={
              searchTerm.length === 0
                ? 'Type something to search!'
                : 'No data found. Try another keyword!'
            }
          />
        ) : (
          <>
            <div className={'search-info'}>
              {lastSearchKeywordRef.current && (
                <p className={'searched-keyword'}>
                  search: <span>{lastSearchKeywordRef.current}</span>
                </p>
              )}
              <p className={'order-count'}>
                found: <span>{orderCount}</span>
              </p>
            </div>

            <OrderItemsContainer>
              {orders.map(order => (
                <Order
                  key={order._id}
                  onClick={() => navigate(`/orders/${order._id}/details`)}>
                  <div className={'name-status'}>
                    <p className={'name'}>{order.customer_name}</p>
                    <p
                      className={
                        'status ' + (order.delivered ? 'completed' : 'pending')
                      }>
                      {order.delivered ? 'Completed' : 'Pending'}
                    </p>
                  </div>
                  <p>cod ({order.remaining})</p>
                  <p>{order.customer_phone}</p>
                  <p className={'order-number'}>#{order.order_number}</p>
                </Order>
              ))}
            </OrderItemsContainer>

            {orderCount > 10 && (
              <Pagination
                totalItems={orderCount}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={newPage => {
                  setCurrentPage(newPage);
                  onSearch(lastSearchKeywordRef.current, newPage);
                }}
              />
            )}
          </>
        ))}
    </Container>
  );
}

export default SearchOrders;

import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import ReportingService from '../services/reporting';
import Loader from '../components/Loader';
import moment from 'moment';
import { useState } from 'react';

const Title = styled.h1`
  font-family: 'Reem Kufi', sans-serif;
  text-align: center;
  margin-top: 15px;
  font-size: 22px;
  color: #e28282;
  text-shadow: 0 2px 2px #cdcdcd;
`;

const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-self: flex-start;

    h3 {
      font-family: 'Reem Kufi', sans-serif;
      color: #767676;
    }

    ion-icon {
      color: #767676;
      font-size: 25px;
      font-weight: bold;
    }
  }
`;

const Card = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: #fafafa;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  width: 100%;
  justify-content: space-between;

  .product-name {
    font-family: 'Reem Kufi', sans-serif;
    font-size: 18px;
  }

  .product-image {
    border-radius: 15px;
  }

  div:first-child,
  div:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  div.divider {
    width: 1px;
    height: 100px;
    border-right: 2px dotted #cdcdcd;
    align-self: center;
  }

  div:nth-child(3) {
    gap: 15px;

    .variant {
      display: flex;
      flex-direction: row;
      min-width: 100px;
      justify-content: space-between;

      p {
        color: #898989;
        font-family: 'Reem Kufi', sans-serif;
        line-height: 20px;
      }
    }
  }
`;

function ProductAnalysis() {
  const { isLoading, data = [] } = useQuery({
    queryKey: ['product-analysis'],
    queryFn: ReportingService.getProductAnalysis,
  });

  const [closeSections, setCloseSections] = useState([]);

  const toggle = date => {
    if (closeSections.includes(date)) {
      setCloseSections(list => list.filter(item => item !== date));
      return;
    }

    setCloseSections(list => [...list, date]);
  };

  return (
    <>
      <Title>Product Analysis</Title>

      {isLoading && <Loader marginTop={'30px'} />}

      {data.map(entry => {
        const isClosed = closeSections.includes(entry.date);

        return (
          <Container>
            <div className="header" onClick={() => toggle(entry.date)}>
              <h3>{moment(entry.date, 'YYYY-MM-DD').format('DD MMM YY')} ({entry.orders.length})</h3>
              {isClosed ? (
                <ion-icon name="chevron-forward-outline"></ion-icon>
              ) : (
                <ion-icon name="chevron-down-outline"></ion-icon>
              )}
            </div>

            {!isClosed && entry.orders.map(product => {
              return (
                <Card key={product.product_id}>
                  <div>
                    <p className="product-name">{product.product_name}</p>
                    <img
                      className="product-image"
                      src={product.product_image}
                      width={175}
                      height={175}
                      alt={product.produce_name}
                    />
                  </div>

                  <div className="divider" />

                  <div>
                    <div className="variant">
                      <p>Variant</p>
                      <p>Qty</p>
                    </div>

                    {product.variants.map(variant => (
                      <div className="variant">
                        <p>{variant.size}</p>
                        <p>{variant.qty}</p>
                      </div>
                    ))}
                  </div>
                </Card>
              );
            })}
          </Container>
        );
      })}
    </>
  );
}

export default ProductAnalysis;

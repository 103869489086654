function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}>
      <h1 style={{ color: '#bababa' }}>404, Page not found :(</h1>
    </div>
  );
}

export default NotFound;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { login } from '../services/auth';

var GlobalStyles = createGlobalStyle`
  body {
    background-color: #ffeaea;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
`;

var Header = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
`;

var ShoppingCartImg = styled.img`
  width: 73px;
  height: 69px;
  margin-right: 10px;
`;

var MainTitle = styled.h1`
  font-family: 'Reem Kufi', sans-serif;
  size: 40px;
  font-weight: 400;
  font-style: normal;
  line-height: 60px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #c12b2b;
`;

var SubTitle = styled.h2`
  font-family: 'Rokkitt', serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  color: #e72828e0;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
`;

var Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

var ErrorAlert = styled.p`
  color: rgba(224, 34, 34, 0.88);
  text-align: center;

  &:before {
    content: '*';
  }
`;

var Input = styled.input`
  margin-top: 15px;
  width: 175px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #c12b2b;
  border-radius: 15px;
  line-height: 30px;
  text-align: center;
  color: #e72828;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  font-family: 'Reem Kufi', sans-serif;

  &:disabled {
    background-color: #efefef;
  }
`;

var Button = styled.button`
  margin-top: 20px;
  background-color: #c12b2b;
  border-radius: 15px;
  border: none;
  font-size: 20px;
  font-family: 'Reem Kufi', sans-serif;
  color: white;
  padding: 0 15px;
  /*width: 130px;*/

  &:hover {
    background-color: #de3030;
  }

  &:disabled {
    background-color: #de7070;
  }
`;

export default function Login() {
  var [email, setEmail] = useState('');
  var [password, setPassword] = useState('');
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');

  var navigate = useNavigate();

  function onSubmit(event) {
    event.preventDefault();

    setLoading(true);

    login({ email, password })
      .then(() => {
        setLoading(false);
        setTimeout(() => navigate('/orders'), 0);
      })
      .catch(() => {
        setLoading(false);
        setError('email or password is wrong!');
      });
  }

  return (
    <>
      <GlobalStyles />
      <Header>
        <ShoppingCartImg
          src="/images/shopping-cart.svg"
          alt="Shopping Cart icon"
        />
        <MainTitle>Jeany's Gems</MainTitle>
      </Header>
      <SubTitle>Login to Continue!</SubTitle>

      {error && <ErrorAlert>{error}</ErrorAlert>}

      <Form onSubmit={onSubmit}>
        <Input
          required
          type="email"
          placeholder={'Email'}
          value={email}
          disabled={loading}
          onChange={event => setEmail(event.target.value)}
        />
        <Input
          required
          type="password"
          placeholder={'Password'}
          value={password}
          disabled={loading}
          onChange={event => setPassword(event.target.value)}
        />
        <Button disabled={!email || !password || loading} type={'submit'}>
          {loading ? 'Logging in...' : 'Login'}
        </Button>
      </Form>
    </>
  );
}

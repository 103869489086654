import { useEffect, useReducer, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import * as yup from 'yup';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import Modal from '../components/Modal';
import ChooseProducts from '../components/ChooseProducts';
import OrderService from '../services/order';

var DESTINATIONS = {
  YGN: 'YANGON',
  MDY: 'MANDALAY',
  ROYAL: 'ROYAL',
  'Pre Order': 'PRE_ORDER',
};

var GlobalStyles = createGlobalStyle`
  body {
    background-color: #ffeaea;
  }
`;

var PageContainer = styled.div`
  padding: 30px 10px 10px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

var HeadLine = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Reem Kufi', sans-serif;
  color: #d77676;

  h1 {
    font-size: 27px;
  }

  p {
    margin-left: 10px;
    margin-top: 3px;
  }
`;

var ItemContainer = styled.div`
  margin-top: 25px;
  display: flex;
  font-family: 'Reem Kufi', sans-serif;
  justify-content: space-between;
  color: #808080;
`;

var ItemImage = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 3px;

  background-image: ${props => `url('${props.src}')`};
  background-size: cover;
`;

var ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => props['alignItems'] || 'flex-start'};
`;

var Row = styled.div`
  display: flex;
`;

var NameQtyPriceCol = styled(ItemColumn)`
  margin-left: 7px;
`;

var Badge = styled.p`
  text-align: center;
  padding: 0 6px;
  background-color: #e58787;
  margin-left: 10px;
  color: white;
  border-radius: 3px;
`;

var QtyBtn = styled.button`
  display: flex;
  align-items: center;
  background-color: #e58787;
  border: none;
  color: white;
  font-size: 20px;
  padding: 0 2px;
  border-radius: 2px;
`;

var RemoveBtn = styled.button`
  background-color: #e58787;
  color: white;
  font-size: 20px;
  border: none;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  border-radius: 3px;

  &:disabled {
    background-color: #eec1c1;
  }
`;

var SelectedCount = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  font-size: 17px;
`;

var Search = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  input {
    font-size: 15px;
    flex-basis: 80%;
    padding: 5px 15px;
    font-family: 'Reem Kufi', sans-serif;
    border: 1px solid #e58787;
    border-radius: 4px;
    color: #e58787;
  }

  button {
    font-size: 15px;
    font-family: 'Reem Kufi', sans-serif;
    flex-basis: 10%;
    background-color: white;
    color: #e58787;
    border: 1px solid #e58787;
    border-radius: 4px;

    transition: background-color linear 0.1s, color linear 0.1s;

    &:disabled {
      border-color: #f3d4d4;
      background-color: #f3d4d4;
      color: white;
    }

    &:hover:not(:disabled) {
      background-color: #e58787;
      color: white;
    }
  }
`;

var StyledForm = styled(Form)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  input {
    margin-top: 15px;
    padding: 6px 12px;
    font-size: 15px;
    font-family: 'Reem Kufi', sans-serif;
    border: none;
    color: #e58787;
    border-radius: 3px;
  }

  div.destination {
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;

    button {
      font-family: 'Reem Kufi', sans-serif;
      border: 1px solid #e07d7d;
      background-color: white;
      color: #e07d7d;
      padding: 5px 8px;
      border-radius: 5px;
    }

    button.active {
      background-color: #e07d7d;
      color: white;
    }
  }

  div.form-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  div.order-summary {
    width: 50%;
    font-family: 'Reem Kufi', sans-serif;
    color: #6d6d6d;
  }

  p.item {
    display: flex;
    justify-content: space-between;
  }

  button[type='submit'] {
    font-family: 'Reem Kufi', sans-serif;
    display: flex;
    align-self: center;
    padding: 4px 7px;
    margin-top: 20px;
    width: 250px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #de8484;
    color: white;
    border: none;
    border-radius: 5px;

    &:hover {
      background-color: #ee8080;
    }

    ion-icon {
      margin-left: 5px;
    }
  }
`;

var ImageInputBox = styled.div`
  background-image: url('${props => props.src}');
  background-size: cover;
  width: 120px;
  height: 120px;
  border-radius: 5px;
`;

var StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  margin-top: 4px;
  font-family: 'Rokkitt', serif;
  font-size: 15px;

  :before {
    content: '*';
  }
`;

function orderItemsReducer(state, action) {
  var getKey = t => t.product_id + t.size;

  switch (action.type) {
    case 'SET_ITEMS': {
      return action.payload.items.reduce(
        (acc, x) => ({ ...acc, [getKey(x)]: x }),
        {}
      );
    }
    case 'INCREASE_QUANTITY': {
      let key = getKey(action.payload);
      return R.evolve({ [key]: R.evolve({ quantity: R.inc }) }, state);
    }
    case 'DECREASE_QUANTITY': {
      let key = getKey(action.payload);
      return R.evolve({ [key]: R.evolve({ quantity: R.dec }) }, state);
    }
    case 'REMOVE_ITEM': {
      let key = getKey(action.payload);
      return R.dissoc(key, state);
    }
    case 'ADD_ITEMS': {
      return R.reduce(
        (acc, x) => {
          var key = getKey(x); // don't add if already exist
          return acc[key] != null ? acc : { ...acc, [getKey(x)]: x };
        },
        state,
        action.payload
      );
    }
    default:
      throw new Error('Unhandled action type!');
  }
}

var orderFormSchema = yup.object({
  customer_name: yup.string().required('Customer name is required.'),
  customer_address: yup.string().required('Customer address is required.'),
  customer_phone: yup.string().required('Customer phone is required.'),
  shipping_fee: yup.number('Shipping fee should be number.'),
  paid_amount: yup.number('Paid amount should be number.'),
  destination: yup.string().required('Please choose one of YGN/MDY/ROYAL.'),
});

function OrderEdit() {
  var { _id } = useParams();
  var navigate = useNavigate();
  var [orderItems, dispatch] = useReducer(orderItemsReducer, {
    items: {},
  });
  var [order, setOrder] = useState({});
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');
  var [searchTerm, setSearchTerm] = useState('');
  var [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);

    OrderService.getOrder(_id, { withStockInfo: true })
      .then(order => {
        setOrder(order);
        dispatch({ type: 'SET_ITEMS', payload: { items: order.items } });
      })
      .catch(() => {
        setError("Something's wrong while loading order info!");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [_id]);

  var subTotal = Object.values(orderItems).reduce(
    (acc, x) => acc + x.price * x.quantity,
    0
  );

  var onChooseProductBoxClose = event => {
    var { type } = event;

    setModalOpen(false);

    if (type === 'CONFIRM') {
      let { data } = event;
      dispatch({ type: 'ADD_ITEMS', payload: data });
    }
  };

  var formInitialValues = order
    ? R.pick(
        [
          'customer_name',
          'customer_address',
          'customer_phone',
          'shipping_fee',
          'paid_amount',
          'destination',
        ],
        order
      )
    : {
        customer_name: '',
        customer_address: '',
        customer_phone: '',
        shipping_fee: '',
        paid_amount: '',
        destination: '',
      };

  var onSubmit = values => {
    var items = R.map(
      R.pick(['product_id', 'size', 'quantity']),
      Object.values(orderItems)
    );

    var orderInfo = R.pick(
      [
        'customer_name',
        'customer_address',
        'customer_phone',
        'shipping_fee',
        'paid_amount',
        'destination',
      ],
      values
    );

    return OrderService.updateOrder(_id, { update: { ...orderInfo, items } })
      .then(() => {
        navigate(-1);
      })
      .catch(axiosError => {
        setError(
          axiosError.response.message ||
            "Something's wrong while updating order!"
        );
      });
  };

  return (
    <>
      <GlobalStyles />
      <PageContainer>
        <HeadLine>
          <h1>Edit Order</h1>

          {!loading && order && <p> #{order.order_number}</p>}
        </HeadLine>

        {loading && <Loader marginTop={'30px'} />}
        {!loading && error && (
          <Alert type={'error'} marginTop={'30px'} message={error} />
        )}

        {!loading && order && (
          <>
            {Object.values(orderItems).map((item, idx) => (
              <ItemContainer key={idx}>
                <Row>
                  <ItemImage src={item.image} alt="" />
                  <NameQtyPriceCol>
                    <div>
                      <p>{item.name}</p>
                      <p>{item.price} mmk</p>
                    </div>
                    <Row>
                      <QtyBtn
                        disabled={item.quantity === 1}
                        onClick={() => {
                          dispatch({
                            type: 'DECREASE_QUANTITY',
                            payload: {
                              product_id: item.product_id,
                              size: item.size,
                            },
                          });
                        }}>
                        <ion-icon name="remove-outline" />
                      </QtyBtn>
                      <SelectedCount>{item.quantity}</SelectedCount>
                      <QtyBtn
                        disabled={item.quantity === item.available_quantity}
                        onClick={() => {
                          dispatch({
                            type: 'INCREASE_QUANTITY',
                            payload: {
                              product_id: item.product_id,
                              size: item.size,
                            },
                          });
                        }}>
                        <ion-icon name="add-outline" />
                      </QtyBtn>
                    </Row>
                  </NameQtyPriceCol>
                </Row>
                <ItemColumn alignItems={'flex-end'}>
                  <Row>
                    <Badge>
                      {item.size} - {item.available_quantity}
                    </Badge>
                  </Row>
                  <RemoveBtn
                    disabled={Object.keys(orderItems).length === 1}
                    onClick={() => {
                      dispatch({
                        type: 'REMOVE_ITEM',
                        payload: {
                          product_id: item.product_id,
                          size: item.size,
                        },
                      });
                    }}>
                    <ion-icon name="trash-outline" />
                  </RemoveBtn>
                </ItemColumn>
              </ItemContainer>
            ))}

            <Search>
              <input
                value={searchTerm}
                onChange={event => {
                  event.preventDefault();
                  event.stopPropagation();

                  setSearchTerm(event.target.value);
                }}
                type="text"
                placeholder={'Enter product name'}
              />
              <button
                onClick={() => setModalOpen(true)}
                disabled={searchTerm.length === 0}>
                Search
              </button>
            </Search>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
              <ChooseProducts
                searchTerm={searchTerm}
                onChooseBoxClose={onChooseProductBoxClose}
                existingItems={Object.values(orderItems).map(
                  R.pick(['product_id', 'size'])
                )}
              />
            </Modal>

            <Formik
              initialValues={formInitialValues}
              validationSchema={orderFormSchema}
              onSubmit={onSubmit}>
              {({ values, setFieldValue, isSubmitting }) => (
                <StyledForm>
                  <Field
                    name="customer_name"
                    type="text"
                    placeholder="Customer name"
                  />
                  <StyledErrorMessage name="customer_name" component={'p'} />

                  <Field
                    name="customer_phone"
                    type="tel"
                    placeholder="Customer phone"
                  />
                  <StyledErrorMessage name="customer_phone" component={'p'} />

                  <Field
                    name="customer_address"
                    type="text"
                    placeholder="Customer address"
                  />
                  <StyledErrorMessage name="customer_address" component={'p'} />

                  <Field
                    name="shipping_fee"
                    type="number"
                    placeholder="Shipping fee"
                    min={0}
                  />
                  <StyledErrorMessage name="shipping_fee" component={'p'} />

                  <Field
                    name="paid_amount"
                    type="number"
                    placeholder="Paid amount"
                    min={0}
                  />
                  <StyledErrorMessage name="paid_amount" component={'p'} />

                  <div className={'destination'}>
                    {Object.keys(DESTINATIONS).map(d => (
                      <button
                        className={
                          values.destination === DESTINATIONS[d] ? 'active' : ''
                        }
                        onClick={event => {
                          event.preventDefault();
                          setFieldValue('destination', DESTINATIONS[d]);
                        }}
                        key={d}>
                        {d}
                      </button>
                    ))}
                  </div>
                  <StyledErrorMessage name="destination" component={'p'} />

                  <div className="form-footer">
                    {order.transaction_image && (
                      <ImageInputBox src={order.transaction_image} />
                    )}

                    <div className={'order-summary'}>
                      {[
                        {
                          label: 'shipping fee',
                          amount: values.shipping_fee || 0,
                        },
                        { label: 'sub total', amount: subTotal },
                        {
                          label: 'total',
                          amount: subTotal + values.shipping_fee,
                        },
                        { label: 'paid', amount: values.paid_amount },
                        {
                          label: 'cod',
                          amount:
                            subTotal + values.shipping_fee - values.paid_amount,
                        },
                      ].map(item => (
                        <p className={'item'} key={item.label}>
                          <span>{item.label}:</span>
                          <span>{item.amount} mmk</span>
                        </p>
                      ))}
                    </div>
                  </div>

                  <button type="submit" disabled={isSubmitting || loading}>
                    {isSubmitting ? (
                      'Submitting...'
                    ) : (
                      <>
                        Submit <ion-icon name="log-in-outline" />
                      </>
                    )}
                  </button>
                </StyledForm>
              )}
            </Formik>
          </>
        )}
      </PageContainer>
    </>
  );
}

export default OrderEdit;

import { useState } from 'react';
import styled from 'styled-components';
import { YEARS, MONTHS } from '../constants';

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledYears = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 75px;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  color: rgba(0, 0, 0, 0.7);

  ion-icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const StyledMonths = styled.div`
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;

  button {
    width: 70px;
    padding: 7px 20px;
    font-size: 20px;
    border-radius: 50px;
    font-family: 'Inder', sans-serif;
    border: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: rgba(00, 00, 00, 0.5);
    background-color: rgba(234, 226, 183, 0.2);

    &:hover {
      background-color: rgba(234, 226, 183, 0.5);
    }

    &.active {
      background-color: rgba(234, 226, 183, 0.5);
    }
  }
`;

var ApplyBtn = styled.button`
  width: 100px;
  padding: 7px 20px;
  font-size: 20px;
  border-radius: 50px;
  font-family: 'Inder', sans-serif;
  border: none;
  margin-top: 5px;
  align-self: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: rgba(00, 00, 00, 0.5);
  background-color: rgba(222, 26, 38, 0.2);

  &:hover {
    background-color: rgba(222, 26, 38, 0.35);
  }
`;

export const MonthPicker = ({ onChange }) => {
  // 2023 at index 2
  const [yearIndex, setYearIndex] = useState(2);
  const [month, setMonth] = useState(0);

  const decreaseYear = () => {
    if (yearIndex > 0) {
      setYearIndex(index => index - 1);
    }
  };

  const increaseYear = () => {
    if (yearIndex < YEARS.length) {
      setYearIndex(index => index + 1);
    }
  };

  return (
    <Container>
      <StyledYears>
        {yearIndex !== 0 && (
          <ion-icon name="arrow-back-outline" onClick={decreaseYear} />
        )}

        <p>{YEARS[yearIndex]}</p>

        {yearIndex !== YEARS.length - 1 && (
          <ion-icon name="arrow-forward-outline" onClick={increaseYear} />
        )}
      </StyledYears>

      <StyledMonths>
        {MONTHS.map((option, index) => (
          <button
            key={option}
            className={index === month ? 'active' : ''}
            onClick={() => setMonth(index)}>
            {option}
          </button>
        ))}
      </StyledMonths>

      <ApplyBtn
        onClick={() => {
          onChange(YEARS[yearIndex], month);
        }}>
        Apply
      </ApplyBtn>
    </Container>
  );
};

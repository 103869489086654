import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

var Wrapper = styled.div`
  background-color: white;
  font-family: 'Reem Kufi', sans-serif;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 10px 10px 18px;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 #e0e0e0;

  @media (max-width: 768px) {
    width: 45vw;
  }

  @media (max-width: 425px) {
    width: 45vw;
  }

  @media (max-width: 320px) {
    width: 90vw;
  }

  &:hover {
    background-color: #f8f8f8;
  }

  p:nth-child(1) {
    font-size: 17px;
  }

  p:nth-child(2),
  p:nth-child(3) {
    color: #646060;
  }

  p:nth-child(3) {
    font-size: 13px;
    color: #e58383;
  }
`;

function OrderItemPreview({ item }) {
  var { customer_name, remaining, order_number } = item;
  var navigate = useNavigate();
  var goToDetailPage = () => navigate(`/orders/${item._id}/details`);

  return (
    <Wrapper onClick={goToDetailPage}>
      <p>{customer_name}</p>
      <p>cod - {remaining}</p>
      <p>{order_number}</p>
    </Wrapper>
  );
}

export default OrderItemPreview;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import Modal from '../components/Modal';
import SearchOrders from '../components/SearchOrders';

var GlobalStyles = createGlobalStyle`
  body {
    background-color: #fff1f1;
  }
`;

var Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

var Title = styled.h1`
  font-family: 'Reem Kufi', sans-serif;
  font-size: 25px;
  font-weight: normal;
  text-align: center;
  color: #cf5858;
  text-shadow: 0 3px 3px #cdcdcd;
  margin-top: 10px;
`;

var Box = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #faeaea;
  }

  p {
    font-family: 'Reem Kufi', sans-serif;
    font-size: 22px;
    font-weight: 550;
    color: #e5626a;
    text-shadow: 0 3px 3px #cdcdcd;
    margin-left: 20px;
  }

  ion-icon {
    font-size: 40px;
    color: #e5626a;
    margin-left: 6px;
  }
`;

var IconImg = styled.img`
  width: 50px;
  height: 50px;
  justify-self: left;
  border-radius: 4px;
`;

var LogoutPrompt = styled.div`
  font-family: 'Reem Kufi', sans-serif;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    text-align: center;
    font-size: 22px;
    color: #db6c6c;
    text-shadow: 0 2px 2px #efb3b3;
    font-weight: normal;
  }

  .actions {
    margin-top: 25px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    button {
      font-family: 'Reem Kufi', sans-serif;
      font-size: 15px;
      border-radius: 10px;
      border: none;
      padding: 5px 10px;
      box-shadow: 0 3px 3px 0 #cdcdcd;
    }

    button:nth-child(1) {
      color: #706f6f;
      margin-right: 20px;
    }

    button:nth-child(2) {
      background-color: #dd8989;
      color: white;
    }
  }
`;

function Orders() {
  var [isLoginPromptOpen, setLoginPromptOpen] = useState(false);
  var [isSearchBoxOpen, setSearchBoxOpen] = useState(false);
  var navigate = useNavigate();
  var goTo = path => () => navigate(path);

  var closeLoginPrompt = type => () => {
    setLoginPromptOpen(false);

    // if (type === 'CANCEL') {
    // }

    if (type === 'CONFIRM') {
      setLoginPromptOpen(false);

      setTimeout(() => {
        localStorage.clear();
        navigate('/login');
      }, 200);
    }
  };

  return (
    <>
      <GlobalStyles />
      <Container>
        <Title>Jeany's Gems Store</Title>
        <Box onClick={goTo('/orders/new')}>
          <IconImg src={'/images/calender.svg'} />
          <p>New Orders</p>
        </Box>

        <Box onClick={goTo('/orders/completed')}>
          <IconImg src={'/images/clock.svg'} />
          <p>Completed Orders</p>
        </Box>

        <Box onClick={goTo('/reports')}>
          <IconImg src={'/images/report.png'} />
          <p>Monthly Report</p>
        </Box>

        <Box onClick={goTo('/sale-analysis')}>
          <IconImg src={'/images/sale-analysis.png'} />
          <p>Sale Analysis</p>
        </Box>

        <Box onClick={goTo('/product-analysis')}>
          <IconImg src={'/images/product-analysis.png'} />
          <p>Product Analysis</p>
        </Box>

        <Box
          onClick={() => {
            setSearchBoxOpen(true);
          }}>
          <IconImg src={'/images/search.svg'} />
          <p>Search</p>
        </Box>

        <Box onClick={() => setLoginPromptOpen(true)}>
          <ion-icon name="log-out-outline" />
          <p>Logout</p>
        </Box>

        <Modal open={isLoginPromptOpen} onClose={closeLoginPrompt()}>
          <LogoutPrompt>
            <h2>Are you sure to logout?</h2>
            <div className={'actions'}>
              <button onClick={closeLoginPrompt('CANCEL')}>Cancel</button>
              <button onClick={closeLoginPrompt('CONFIRM')}>
                Yes, confirm
              </button>
            </div>
          </LogoutPrompt>
        </Modal>

        <Modal open={isSearchBoxOpen} onClose={() => setSearchBoxOpen(false)}>
          <SearchOrders />
        </Modal>
      </Container>
    </>
  );
}

export default Orders;

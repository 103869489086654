import styled from 'styled-components';
import { createRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Alert from '../components/Alert';

import { uploadImageToS3 } from '../services/image';
import ProductService from '../services/stocks';

var Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

var Title = styled.h2`
  margin-top: 20px;
  text-align: center;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 22px;
  text-shadow: 0 4px 4px rgba(64, 64, 64, 0.25);
  color: #e5626a;
`;

var Form = styled.form`
  margin-top: 15px;
  width: 500px;

  @media (max-width: 768px) {
    width: 75vw;
  }

  @media (max-width: 425px) {
    width: 85vw;
  }

  @media (max-width: 320px) {
    width: 95vw;
  }

  div.footer {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

var Input = styled.input`
  margin-bottom: 15px;
  width: 100%;
  padding: 5px 10px;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: 2px solid #ffeaea;
  color: #e5626a;

  &:focus,
  &:hover {
    border: 2px solid #eca8a8;
  }

  transition: border-color ease 0.45s;
`;

var SizeQuantityRow = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

var SizeInput = styled(Input)`
  margin: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

var QuantityInput = styled(Input)`
  margin: 0;
  border-right: none;
  border-radius: 0;
`;

var IconBtn = styled.button`
  padding: 8px;
  background-color: #ea6b72;
  color: white;
  ${props =>
    props.rounded
      ? 'border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;'
      : ''}
  border: none;
  display: flex;
  align-items: center;

  ion-icon {
    font-weight: bold;
    font-size: 1.1rem;
  }

  &:disabled {
    background-color: #ec9ea4;
  }
`;

var AddStockBtn = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Reem Kufi', sans-serif;
  color: white;
  background-color: #ea6b72;
  border: none;
  padding: 5px 14px;
  border-radius: 4px;
  margin-left: 10px;

  ion-icon {
    margin-left: 5px;
  }
`;

var ImageInputBox = styled.div`
  cursor: pointer;
  border-radius: 4px;
  width: 120px;
  height: 120px;
  border: 2px solid #ffeaea;
  font-family: 'Reem Kufi', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e5626a;

  ion-icon {
    margin-left: 5px;
  }
`;

var ImageBox = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  border-radius: 4px;
`;

var SubmitButton = styled.button`
  margin-top: 15px;
  padding: 5px 10px;
  border: none;
  background-color: #e77e7e;
  font-size: 16px;
  font-family: 'Reem Kufi', sans-serif;
  border-radius: 3px;
  color: white;

  :hover {
    background-color: #ea6b6b;
  }
`;

function StockForm() {
  var [name, setName] = useState('');
  var [price, setPrice] = useState('');
  var [pairs, setPairs] = useState([['', '']]);

  var [currentImage, setCurrentImage] = useState(null);
  var [imageBlob, setImageBlog] = useState(null);

  var [loading, setLoading] = useState(false);
  var [error, setError] = useState(null);

  var imageInputRef = createRef();
  var navigate = useNavigate();

  function onImageChange(event) {
    var f = event.target.files[0];
    setCurrentImage(f);
    setImageBlog(URL.createObjectURL(f));
  }

  function onAddBtnClick(event) {
    event.preventDefault();
    setPairs([...pairs, ['', '']]);
  }

  function onRemoveBtnClick(event, idx) {
    event.preventDefault();
    var filtered = pairs.filter((_, currentIndex) => currentIndex !== idx);
    setPairs(filtered);
  }

  function sizeQuantityChangeHandler({ value, idx, type }) {
    var calculated = pairs.map((p, index) => {
      if (index !== idx) return p;
      return [
        type === 'size' ? value : p[0],
        type === 'quantity' ? value : p[1],
        p[2],
      ];
    });

    setPairs(calculated);
  }

  function onSubmit(event) {
    event.preventDefault();

    if (!currentImage) setError('*Please select an image.');

    setLoading(true);

    var in_stock = pairs.map(([size, quantity]) => {
      return {
        size,
        quantity: Number(quantity),
      };
    });

    uploadImageToS3({ file: currentImage, type: 'product' })
      .then(imageUrl =>
        ProductService.createProduct({
          name,
          price,
          image: imageUrl,
          in_stock,
        })
      )
      .then(() => navigate('/stocks'))
      .catch(error => {
        Sentry.captureException(error);
        setLoading(false);
        setError("Something's wrong while uploading image!");
      });
  }

  return (
    <Wrapper>
      <Title>Create new product</Title>

      {error && (
        <Alert type={'error'} message={error.message} marginTop={'5px'} />
      )}

      <Form onSubmit={onSubmit}>
        <Input
          required
          type="text"
          placeholder={'Name'}
          value={name}
          onChange={event => setName(event.target.value)}
          disabled={loading}
        />
        <Input
          required
          type="number"
          placeholder={'Price'}
          value={price}
          onChange={event => setPrice(event.target.value)}
          disabled={loading}
        />
        {pairs.map(([size, quantity], idx) => {
          return (
            <SizeQuantityRow key={idx}>
              <SizeInput
                required
                type={'text'}
                placeholder={'Size'}
                value={size}
                onChange={event =>
                  sizeQuantityChangeHandler({
                    value: event.target.value,
                    type: 'size',
                    idx,
                  })
                }
                disabled={loading}
              />
              <QuantityInput
                required
                type={'number'}
                placeholder={'Quantity'}
                value={quantity}
                onChange={event =>
                  sizeQuantityChangeHandler({
                    value: event.target.value,
                    type: 'quantity',
                    idx,
                  })
                }
                disabled={loading}
              />
              <IconBtn
                onClick={event => onRemoveBtnClick(event, idx)}
                rounded
                disabled={pairs.length === 1}>
                <ion-icon name="trash-outline" />
              </IconBtn>
            </SizeQuantityRow>
          );
        })}

        <div className={'footer'}>
          <ImageInputBox
            onClick={() => {
              imageInputRef.current.click();
            }}>
            {imageBlob ? (
              <ImageBox imageUrl={imageBlob} />
            ) : (
              <>
                Choose Img
                <ion-icon name="cloud-upload-outline" />
              </>
            )}
          </ImageInputBox>

          <AddStockBtn onClick={onAddBtnClick}>
            Add stock
            <ion-icon name="add-outline" />
          </AddStockBtn>
        </div>

        <input
          ref={imageInputRef}
          type="file"
          hidden
          onChange={onImageChange}
          disabled={loading}
        />
        <SubmitButton type={'submit'} disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </SubmitButton>
      </Form>
    </Wrapper>
  );
}

export default StockForm;

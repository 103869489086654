export function stocksReducer(state, action) {
  switch (action.type) {
    case 'INIT_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'LOADING_SUCCESS':
      return {
        ...state,
        loading: false,
        stocks: action.payload.data,
        count: action.payload.count,
      };

    case 'LOADING_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

import styled from 'styled-components';

var Wrapper = styled.div`
  position: fixed;
  background-color: rgba(22, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
`;

var Content = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: white;
  width: 500px;
  max-height: 75vh;
  margin: 0 auto;
  padding-bottom: 15px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 75vw;
  }

  @media (max-width: 425px) {
    width: 80vw;
  }

  @media (max-width: 320px) {
    width: 90vw;
  }

  // this is for close btn
  > ion-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #818181;
    font-size: 22px;

    :hover {
      color: #403e3e;
    }
  }
`;

function Modal({ open, onClose = v => v, children }) {
  return open ? (
    <Wrapper>
      <Content>
        <ion-icon name="close-outline" onClick={onClose} />
        {children}
      </Content>
    </Wrapper>
  ) : (
    <></>
  );
}

export default Modal;

import { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import Modal from '../components/Modal';
import OrderService from '../services/order';
import { uploadImageToS3 } from '../services/image';

const DESTINATION = {
  YANGON: 'YGN',
  MANDALAY: 'MDY',
  ROYAL: 'ROYAL',
  PRE_ORDER: 'Pre Order',
};

var Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

var CustomerDetail = styled.div`
  width: 30%;
  padding: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 80vw;
  }

  @media (max-width: 425px) {
    width: 90vw;
  }

  @media (max-width: 320px) {
    width: 95vw;
  }
`;

var OrderItemsSection = styled.div`
  width: 65%;

  @media (max-width: 768px) {
    width: 80vw;
  }

  @media (max-width: 425px) {
    width: 90vw;
  }

  @media (max-width: 320px) {
    width: 95vw;
  }
`;

var OrderItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
`;

var CustomerInfoRow = styled.div`
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 17px;

  span {
    color: #5d5d5d;
  }

  span.destination,
  span.delivery-status {
    background-color: #e29090;
    padding: 0 10px;
    border-radius: 20px;
    color: white;
  }

  span.pending {
    background-color: #e29090;
  }

  span.completed {
    background-color: #90e290;
  }
`;

var Actions = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

var Btn = styled.button`
  font-family: 'Reem Kufi', sans-serif;
  border: none;
  border-radius: 3px;
  padding: 3px 8px;
  background-color: #e29090;
  color: white;
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-left: 3px;

  &:hover {
    background-color: #ba7f7f;
  }

  ion-icon {
    font-size: 16px;
  }

  span.ml-3 {
    margin-left: 3px;
  }

  span.mr-3 {
    margin-right: 3px;
  }
`;

var BackButton = styled.button`
  font-family: 'Reem Kufi', sans-serif;
  border: none;
  background-color: white;
  color: #e29090;
  display: flex;
  align-items: center;
  font-size: 20px;

  ion-icon {
    margin-right: 3px;
  }
`;

var HR = styled.hr`
  width: 70%;
  margin: 15px auto;
`;

var SubTitle = styled.h2`
  text-align: center;
  font-family: 'Reem Kufi', sans-serif;
  text-shadow: 0 2px 2px #eecccc;
  color: #e77575;
  font-size: 20px;
`;

var OrderItem = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    border: 1px solid #e3e3e3;
    border-radius: 7px;
    width: 150px;
    height: 150px;
  }

  p {
    font-family: 'Reem Kufi', sans-serif;
  }

  div.info {
    display: flex;
    justify-content: space-around;

    p {
      text-align: center;
      width: 100%;
    }

    p:nth-child(1),
    p:nth-child(2) {
      border-right: 1px solid #f3c3c3;
    }
  }
`;

var DeleteConfirmation = styled.div`
  font-family: 'Reem Kufi', sans-serif;

  h2 {
    margin-top: 20px;
    text-align: center;
    color: #e07a7a;
    text-shadow: 0 2px 2px #e7b3b3;
    font-size: 20px;
    font-weight: normal;
  }

  div {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  button {
    border: none;
    font-family: 'Reem Kufi', sans-serif;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 7px;
    box-shadow: 0 4px 4px 0 #d7d7d7;
  }

  button:nth-child(1) {
    margin-right: 20px;
  }

  button:nth-child(2) {
    color: white;
    background-color: #e57f7f;
  }
`;

var TranxImageInput = styled.div`
  border: 2px dashed #f3c3c3;
  width: 130px;
  height: 130px;
  margin-top: 18px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  p {
    color: #e28c8c;
    font-family: 'Reem Kufi', sans-serif;
  }
`;

var TranxImage = styled.div`
  margin-top: 10px;
  margin-right: 10px;

  div:nth-child(1) {
    border: 1px solid #e3e3e3;
    border-radius: 7px;
    width: 150px;
    height: 150px;
    background-image: url(${props => props.imageUrl});
    background-size: cover;
  }

  div:nth-child(2) {
    margin-top: 20px;
    display: flex;
  }
`;

var TranxImageDetailsFooter = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 5px;
    border: 1px solid #efb3b3;
    background-color: #efb3b3;
    color: white;
    width: 70%;
    font-family: 'Reem Kufi', sans-serif;
    font-size: 16px;
  }
`;

function OrderDetail() {
  var { _id } = useParams();

  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');
  var [order, setOrder] = useState(null);
  var [confirmationDialogOpen, setConfirmDialogOpen] = useState(false);
  var [imageDialogOpen, setImageDialogOpen] = useState(false);
  var navigate = useNavigate();
  var tranxImageInputRef = createRef();

  useEffect(() => {
    setLoading(true);

    OrderService.getOrder(_id)
      .then(setOrder)
      .catch(() => {
        setError("Something's wrong while loading order details!");
      })
      .finally(() => setLoading(false));
  }, [_id]);

  var completeOrder = goBackTo => {
    setLoading(true);

    OrderService.updateOrderStatus(_id, { delivered: true })
      .then(() => {
        navigate(goBackTo);
      })
      .catch(() => {
        setLoading(false);
        setError("Something's wrong while updating order status!");
      });
  };

  var deleteOrder = () => {
    setLoading(true);

    return OrderService.deleteOrder(_id)
      .then(() => {
        navigate(-1);
      })
      .catch(() => {
        setLoading(false);
        setError("Something's wrong while updating order status!");
      });
  };

  var onFileChange = event => {
    event.preventDefault();

    var f = event.target.files[0];

    if (order && f) {
      setLoading(true);

      uploadImageToS3({ file: f, type: 'order' })
        .then(url =>
          OrderService.updateTranxImage(order._id, { imageUrl: url })
        )
        .then(() => window.location.reload())
        .catch(() => {
          setLoading(false);
          setError("Something's wrong while uploading new tranx image!");
        });
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate(-1)}>
        <ion-icon name="arrow-back-outline" />
        <span className={'ml-3'}>Go back</span>
      </BackButton>

      {loading && <Loader marginTop={'100px'} />}

      {!loading && error && (
        <Alert
          type={'error'}
          message={error || "Something's wrong while loading data!"}
          marginTop={'15px'}
        />
      )}

      {!loading && order && (
        <Container>
          <CustomerDetail>
            <CustomerInfoRow>
              <p>Order number:</p>
              <span>#{order.order_number}</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Customer name:</p>
              <span>{order.customer_name}</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Customer phone:</p>
              <span>{order.customer_phone}</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Customer address:</p>
              <span>{order.customer_address}</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Order destination:</p>
              <span className={'destination'}>
                {DESTINATION[order.destination]}
              </span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Status:</p>
              <span
                className={
                  'delivery-status ' +
                  (order.delivered ? 'completed' : 'pending')
                }>
                {order.delivered ? 'Completed' : 'Pending'}
              </span>
            </CustomerInfoRow>
            <HR />
            <CustomerInfoRow>
              <p>Sub Total:</p>
              <span>{order.sub_total} mmk</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Shipping fee:</p>
              <span>{order.shipping_fee} mmk</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Total:</p>
              <span>{order.total} mmk</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Paid:</p>
              <span>{order.paid_amount} mmk</span>
            </CustomerInfoRow>
            <CustomerInfoRow>
              <p>Remaining(COD):</p>
              <span>{order.remaining} mmk</span>
            </CustomerInfoRow>
            <Actions>
              <Btn onClick={() => navigate(`/orders/${_id}/invoice`)}>
                <span className={'mr-3'}>Invoice</span>
                <ion-icon name="receipt-outline" />
              </Btn>

              {!order.delivered && (
                <>
                  <Btn
                    onClick={() =>
                      completeOrder(
                        `/orders/new?destination=${order.destination}`
                      )
                    }
                    disabled={loading}>
                    <span className="mr-3">Complete</span>
                    <ion-icon name="checkmark-circle-outline" />
                  </Btn>
                  <Btn onClick={() => navigate(`/orders/${_id}/edit`)}>
                    <span className={'mr-3'}>Edit</span>
                    <ion-icon name="create-outline" />
                  </Btn>
                  <Btn onClick={() => setConfirmDialogOpen(true)}>
                    <span className={'mr-3'}>Delete</span>
                    <ion-icon name="trash" />
                  </Btn>
                </>
              )}
            </Actions>
          </CustomerDetail>
          <OrderItemsSection>
            <SubTitle>Order Items</SubTitle>

            <OrderItemsWrapper>
              <input
                ref={tranxImageInputRef}
                type="file"
                hidden
                onChange={onFileChange}
              />

              {!order.transaction_image && (
                <TranxImageInput
                  onClick={() =>
                    tranxImageInputRef && tranxImageInputRef.current.click()
                  }>
                  <p>
                    Add Tranx <br /> Image
                  </p>
                </TranxImageInput>
              )}

              {order.transaction_image && (
                <TranxImage imageUrl={order.transaction_image}>
                  <div className={'img'} />
                  <div className={'footer'}>
                    <Btn onClick={() => setImageDialogOpen(true)}>
                      <span className={'mr-3'}>ကြီးကြီး?</span>
                      <ion-icon name="eye-outline" />
                    </Btn>
                    <Btn
                      onClick={() =>
                        tranxImageInputRef.current &&
                        tranxImageInputRef.current.click()
                      }>
                      <span className={'mr-3'}>Edit</span>
                      <ion-icon name="create-outline" />
                    </Btn>
                  </div>
                </TranxImage>
              )}

              {order.items.map((item, idx) => {
                return (
                  <OrderItem key={idx}>
                    <div>
                      <img src={item.image} alt="" />
                      <p>{item.name}</p>
                    </div>
                    <div className={'info'}>
                      <p>{item.price}</p>
                      <p>{item.size}</p>
                      <p>{item.quantity}</p>
                    </div>
                  </OrderItem>
                );
              })}
            </OrderItemsWrapper>
          </OrderItemsSection>
        </Container>
      )}

      <Modal
        open={confirmationDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}>
        <DeleteConfirmation>
          <h2>Are you sure to delete this order?</h2>
          <div>
            <button onClick={() => setConfirmDialogOpen(false)}>Cancel</button>
            <button
              onClick={() => {
                setConfirmDialogOpen(false);
                setTimeout(deleteOrder, 0);
              }}>
              Confirm
            </button>
          </div>
        </DeleteConfirmation>
      </Modal>

      <Modal open={imageDialogOpen} onClose={() => setImageDialogOpen(false)}>
        {order && order.transaction_image && (
          <img
            src={order.transaction_image}
            style={{ maxWidth: '100%', height: '100%' }}
            alt={'Tranx'}
          />
        )}

        <TranxImageDetailsFooter>
          <button onClick={() => setImageDialogOpen(false)}>Close</button>
        </TranxImageDetailsFooter>
      </Modal>
    </>
  );
}

export default OrderDetail;

export const LOCAL_STORAGE_USER_KEY = 'jeany-gem:user';
export const LOCAL_STORAGE_TOKEN_KEY = 'jeany-gem:token';
export const YEARS = [2021, 2022, 2023, 2024];
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

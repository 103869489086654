import { createGlobalStyle } from 'styled-components';

var GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;

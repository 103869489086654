import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

// components
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import OrderItemPreview from '../components/OrderItemPreview';

// other stuffs
import OrderService from '../services/order';

var Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
`;

var Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  div.info {
    margin-top: 15px;
    display: flex;
    align-items: center;
    
    h1 {
      font-family: 'Reem Kufi', sans-serif;
      font-weight: normal;
      font-size: 25px;
      color: #d45050;
      text-shadow: 0 3px 3px #cdcdcd;
      margin-right: 10px;
    }

    p {
      font-size: 18px;
      color: white;
      background-color: #e28c8c;
      padding: 2px 8px;
      border-radius: 5px;
    }
  }

  div.actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      font-family: 'Reem Kufi', sans-serif;
      font-size: 15px;
      padding: 4px 13px 4px 20px;
      background-color: white;
      border: 2px solid #f3bdbd;
      border-radius: 8px;
      color: #e97e7e;
      margin-right: 7px;
    }
  }
`;

var CustomDateInputContainer = styled.div`
  border: 2px solid #f3bdbd;
  border-radius: 8px;
  font-family: 'Reem Kufi', sans-serif;
  color: #e97e7e;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  max-width: 150px;

  p {
    padding: 3px 7px;
  }

  button {
    background-color: #f1dada;
    border: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    ion-icon {
      font-size: 19px;
      color: #e75858;
      font-weight: bold;
    }

    &:hover {
      background-color: #f3bdbd;
    }
  }
`;

var Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
`;

const ITEMS_PER_PAGE = 20;
const getDateRange = date => {
  const config = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  var start = moment(date).set(config).toISOString();
  var end = moment(date).set(config).add(1, 'day').toISOString();

  return {
    start_date: start,
    end_date: end,
  };
};

function CompletedOrders() {
  var [searchParams, setSearchParams] = useSearchParams();
  var [selectedDate, setSelectedDate] = useState('');
  var [currentPage, setCurrentPage] = useState(1);
  var [orders, setOrders] = useState([]);
  var [totalCount, setTotalCount] = useState(0);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');

  var skip = (currentPage - 1) * ITEMS_PER_PAGE;
  var destination = searchParams.get('destination');

  destination = destination ? destination.toUpperCase() : 'YANGON';

  useEffect(() => {
    setLoading(true);

    OrderService.getOrders({
      destination,
      delivered: true,
      limit: ITEMS_PER_PAGE,
      skip,
      ...(selectedDate && getDateRange(selectedDate)),
    })
      .then(response => {
        setOrders(response.data);
        setTotalCount(response.count);
      })
      .catch(() =>
        setError("Something's wrong while loading completed orders!")
      )
      .finally(() => setLoading(false));
  }, [skip, currentPage, destination, selectedDate]);

  var CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <CustomDateInputContainer onClick={onClick} ref={ref}>
      <p>{value || 'Choose date...'}</p>
      {value && (
        <button
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            setSelectedDate('');
          }}>
          <ion-icon name="close-outline" />
        </button>
      )}
    </CustomDateInputContainer>
  ));

  return (
    <Container>
      <Header>
        <div className="info">
          <h1>Completed orders</h1>
          <p>{totalCount}</p>
        </div>

        <div className="actions">
          <select
            value={destination}
            onChange={event => {
              event.preventDefault();
              setSearchParams({ destination: event.target.value });
            }}>
            <option value="YANGON">YGN</option>
            <option value="MANDALAY">MDY</option>
            <option value="ROYAL">ROYAL</option>
            <option value="PRE_ORDER">Pre Order</option>
          </select>

          <DatePicker
            selected={selectedDate}
            onSelect={setSelectedDate}
            customInput={<CustomDateInput />}
          />
        </div>
      </Header>

      <>
        {loading && <Loader marginTop={'100px'} />}

        {!loading && error && (
          <Alert
            type={'error'}
            message={
              error.message ||
              "Something's wrong while loading completed orders!"
            }
          />
        )}

        {!loading && !error && orders.length === 0 && (
          <Alert
            marginTop={'100px'}
            type={'success'}
            message={
              'No items yet. Please make at least one completed order and come back :('
            }
          />
        )}

        {!loading && orders.length > 0 && (
          <>
            <Content>
              {orders.map((item, idx) => (
                <OrderItemPreview item={item} key={idx} />
              ))}
            </Content>
            <Pagination
              totalItems={totalCount}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
          </>
        )}
      </>
    </Container>
  );
}

export default CompletedOrders;

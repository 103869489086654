/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useContext, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { assoc, curry, dissoc, prop } from 'ramda';
import * as yup from 'yup';

import Loader from '../components/Loader';
import Alert from '../components/Alert';

import OrderService from '../services/order';
import ProductService from '../services/stocks';
import { uploadImageToS3 } from '../services/image';
import { CartContext } from '../context/CartContext';

const DESTINATIONS = {
  YGN: 'YANGON',
  MDY: 'MANDALAY',
  ROYAL: 'ROYAL',
  'Pre Order': 'PRE_ORDER',
};

var GlobalStyles = createGlobalStyle`
  body {
    background-color: #ffeaea;
  }
`;

var Title = styled.h1`
  font-family: 'Reem Kufi', sans-serif;
  font-size: 22px;
  text-align: center;
  margin-top: 10px;
  color: #de6e6e;
  text-shadow: 0 4px 4px #cdcdcd;
`;

var Container = styled.div`
  margin: 10px auto;
  max-width: 500px;
`;

var CartsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

var CartItemContainer = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 15px;
  box-shadow: 0 4px 4px 0 #cdcdcd;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
`;

var CartItemImage = styled.div`
  width: 70px;
  height: 70px;
  background-image: ${props => `url('${props.imageUrl}')`};
  background-size: cover;
  border-radius: 7px;
`;

var CartItemInfo = styled.div`
  font-family: 'Reem Kufi', sans-serif;
  display: flex;
  flex-direction: column;
  margin-left: 4px;

  div.name-size {
    display: flex;
    align-items: center;

    p.size,
    p.available-quantity {
      margin-left: 5px;
      background-color: #efb3b3;
      color: white;
      padding: 3px 7px;
      border-radius: 7px;
    }
  }

  p.price {
    font-size: 14px;
    color: #797777;
  }
`;

var QuantitySection = styled.div`
  margin-top: 3px;
  display: flex;

  button {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    background-color: white;
    font-size: 22px;
    color: #cf7b7b;
  }

  p {
    font-size: 17px;
    margin-left: 6px;
    margin-right: 6px;
  }
`;

var RemoveItemBtn = styled.button`
  background-color: #e79a9a;
  color: white;
  padding: 5px 7px;
  align-self: flex-end;
  border: none;
  border-radius: 3px;
  position: absolute;
  right: 5px;

  ion-icon {
    font-size: 17px;
  }
`;

var StyledForm = styled(Form)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

var StyledField = styled(Field)`
  padding: 5px 10px;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #efb3b3;
  margin-top: 15px;
`;

var OptionsInputBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;

  button {
    font-family: 'Reem Kufi', sans-serif;
    border: none;
    background-color: white;
    color: #e07d7d;
    padding: 8px 11px;
    font-weight: bold;
    border-radius: 5px;
  }

  button.active {
    background-color: #e07d7d;
    color: white;
  }
`;

var ImageInputBox = styled.div`
  margin-bottom: 5px;
  background-color: white;
  background-image: ${props => `url('${props.imageUrl}')`};
  background-size: cover;
  width: 120px;
  height: 100px;
  ${props => !props.imageUrl && 'border: 2px dashed #cdcdcd;'}
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Reem Kufi', sans-serif;

  p {
    color: #837e7e;
    text-align: center;
  }
`;

var StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  margin-top: 4px;
  font-family: 'Rokkitt', serif;
  font-size: 15px;

  :before {
    content: '*';
  }
`;

var FormFooter = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

var OrderSummary = styled.div`
  width: 50%;

  p {
    color: #585252;
    display: flex;
    justify-content: space-between;
    font-family: 'Reem Kufi', sans-serif;
    font-size: 15px;
  }
`;

var SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 70%;
  align-self: center;
  padding: 5px 0;
  border: none;
  background-color: #de8484;
  color: white;
  font-weight: bold;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 16px;
  border-radius: 5px;

  ion-icon {
    font-size: 18px;
    margin-left: 4px;
  }

  :disabled {
    background-color: #eaacac;
  }
`;

var renameKey = curry((oldKey, newKey, obj) =>
  assoc(newKey, prop(oldKey, obj), dissoc(oldKey, obj))
);

var orderSchema = yup.object({
  customer_name: yup.string().required('Customer name is required.'),
  customer_phone: yup.string().required('Customer phone is required.'),
  customer_address: yup.string().required('Customer address is required.'),
  order_destination: yup
    .string()
    .required('Please choose one of YGN/MDY/ROYAL/Pre Order.'),
});

function CartItem({
  _id,
  name,
  image,
  price,
  size,
  selectedQuantity,
  availableQuantity,
}) {
  var { increaseQuantity, decreaseQuantity, removeItem } =
    useContext(CartContext);

  return (
    <CartItemContainer>
      <CartItemImage imageUrl={image} />
      <CartItemInfo>
        <div className={'name-size'}>
          <p>{name}</p>
          <p className={'size'}>{size}</p>
          <p className={'available-quantity'}>{availableQuantity}</p>
        </div>
        <p className={'price'}>{price * selectedQuantity} mmk</p>
        <QuantitySection>
          <button
            onClick={() => {
              if (selectedQuantity > 1) {
                decreaseQuantity({ product_id: _id, size });
              }
            }}
            disabled={selectedQuantity === 1}>
            <ion-icon name="remove-circle-outline" />
          </button>
          <p>{selectedQuantity}</p>
          <button
            onClick={() => {
              if (selectedQuantity < availableQuantity) {
                increaseQuantity({ product_id: _id, size });
              }
            }}
            disabled={selectedQuantity === availableQuantity}>
            <ion-icon name="add-circle-outline" />
          </button>
        </QuantitySection>
      </CartItemInfo>
      <RemoveItemBtn>
        <ion-icon
          name="trash-outline"
          onClick={event => {
            event.preventDefault();
            removeItem({ product_id: _id, size });
          }}
        />
      </RemoveItemBtn>
    </CartItemContainer>
  );
}

function merge(items, products) {
  return items
    .map((t, idx) => {
      var { product_id, size, qty } = t;
      var product = products.find(p => p._id === product_id);
      var stk = product && product.in_stock.find(stk => stk.size === size);

      if (product && stk) {
        console.log();
        return {
          key: idx,
          _id: product._id,
          name: product.name,
          image: product.image,
          price: product.price,
          size,
          selectedQuantity: qty,
          availableQuantity: stk.quantity,
        };
      }

      return null;
    })
    .filter(Boolean);
}

function Checkout() {
  var { product_ids, cartItems, clearCart } = useContext(CartContext);

  var [products, setProducts] = useState([]);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState(null);
  var [tranxImgBlob, setTranxImgBlob] = useState('');

  var tranxImgInputRef = createRef();
  var navigate = useNavigate();
  var items =
    product_ids.length === 0 || products.length === 0
      ? []
      : merge(cartItems, products);

  useEffect(() => {
    setLoading(true);

    ProductService.getProducts({
      all: true,
      products_in: product_ids.join(','),
    })
      .then(response => response.data)
      .then(setProducts)
      .catch(error => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  var initialFormValues = {
    shipping_fee: '',
    customer_name: '',
    customer_phone: '',
    customer_address: '',
    paid_amount: '',
    order_image: null,
    order_destination: '',
  };

  var subTotal = items.reduce(
    (acc, x) => acc + x.price * x.selectedQuantity,
    0
  );

  var onFileChange = event => {
    var f = event.target.files[0];

    if (f) {
      setTranxImgBlob(URL.createObjectURL(f));
    } else {
      setTranxImgBlob('');
    }
  };

  async function onSubmitForm(values) {
    var tranxImgUrl = '';

    var shipFee = values.shipping_fee || 0;
    var paid = values.paid_amount || 0;

    if (values.order_image != null) {
      tranxImgUrl = await uploadImageToS3({
        file: values.order_image,
        type: 'order',
      });
    }

    return OrderService.createOrder({
      customer_name: values.customer_name,
      customer_phone: values.customer_phone,
      customer_address: values.customer_address,
      destination: values.order_destination,
      items: values.items.map(renameKey('qty', 'quantity')),
      shipping_fee: shipFee,
      paid_amount: paid,
      transaction_image: tranxImgUrl,
    })
      .then(order => {
        // important
        clearCart({});
        navigate(`/orders/${order._id}/invoice`);
      })
      .catch(axiosError => {
        if (axiosError.response.data.message === 'Not enough quantity!') {
          setError(axiosError.response.data.message);
        } else {
          setError("Something's wrong while creating order!");
        }
      });
  }

  return (
    <>
      <GlobalStyles />
      <Title>Checkout</Title>

      {loading && <Loader marginTop={'100px'} />}

      {!loading && error && (
        <Alert
          type={'error'}
          message={error || "Something's wrong while loading cart items!"}
        />
      )}

      {!loading && product_ids.length === 0 ? (
        <Alert
          type={'success'}
          message={'Please select more items and come back!'}
        />
      ) : (
        <Container>
          <CartsContainer>
            {items.map(item => (
              <CartItem {...item} />
            ))}
          </CartsContainer>

          <Formik
            initialValues={initialFormValues}
            validationSchema={orderSchema}
            onSubmit={values => onSubmitForm({ ...values, items: cartItems })}>
            {({ values, setFieldValue, isSubmitting }) => (
              <StyledForm>
                <StyledField
                  name={'customer_name'}
                  type={'text'}
                  placeholder={'Customer name'}
                />
                <StyledErrorMessage name={'customer_name'} component={'p'} />

                <StyledField
                  name={'customer_phone'}
                  type={'text'}
                  placeholder={'Customer phone'}
                />
                <StyledErrorMessage name={'customer_phone'} component={'p'} />

                <StyledField
                  name={'customer_address'}
                  type={'text'}
                  placeholder={'Customer address'}
                />
                <StyledErrorMessage name={'customer_address'} component={'p'} />

                <StyledField
                  name={'shipping_fee'}
                  type={'number'}
                  placeholder={'Shipping fee'}
                />
                <StyledErrorMessage name={'shipping_fee'} component={'p'} />

                <StyledField
                  name={'paid_amount'}
                  type={'number'}
                  placeholder={'Paid amount'}
                />

                <OptionsInputBox>
                  {Object.keys(DESTINATIONS).map(k => (
                    <button
                      key={k}
                      onClick={event => {
                        event.preventDefault();
                        setFieldValue('order_destination', DESTINATIONS[k]);
                      }}
                      className={
                        values.order_destination === DESTINATIONS[k]
                          ? 'active'
                          : ''
                      }>
                      {k}
                    </button>
                  ))}
                </OptionsInputBox>
                <StyledErrorMessage
                  name={'order_destination'}
                  component={'p'}
                />

                <FormFooter>
                  <ImageInputBox
                    imageUrl={tranxImgBlob}
                    onClick={event => {
                      event.preventDefault();
                      tranxImgInputRef.current.click();
                    }}>
                    {!tranxImgBlob && <p>add photo (optional)</p>}
                  </ImageInputBox>

                  <input
                    ref={tranxImgInputRef}
                    name={'order_image'}
                    type="file"
                    hidden
                    onChange={event => {
                      event.preventDefault();
                      setFieldValue('order_image', event.target.files[0]);
                      onFileChange(event);
                    }}
                  />

                  <OrderSummary>
                    <p>
                      shipping fee: <span>{values.shipping_fee || 0} mmk</span>{' '}
                    </p>
                    <p>
                      subtotal : <span>{subTotal} mmk</span>{' '}
                    </p>
                    <p>
                      total:{' '}
                      <span>{(values.shipping_fee || 0) + subTotal} mmk</span>
                    </p>
                    <p>
                      paid: <span>{values.paid_amount || 0} mmk</span>
                    </p>
                    <p>
                      cod:{' '}
                      <span>
                        {(values.shipping_fee || 0) +
                          subTotal -
                          (values.paid_amount || 0)}{' '}
                        mmk
                      </span>
                    </p>
                  </OrderSummary>
                </FormFooter>

                <SubmitBtn disabled={isSubmitting || loading} type={'submit'}>
                  {isSubmitting || loading ? 'Submitting...' : 'Submit'}
                  <ion-icon name="log-in-outline" />
                </SubmitBtn>
              </StyledForm>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
}

export default Checkout;

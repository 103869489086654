import axios from 'axios';
import { LOCAL_STORAGE_TOKEN_KEY } from './constants';

var axiosInstance = axios.create({ baseURL: process.env.REACT_API_URL });

axiosInstance.interceptors.request.use(config => {
  var authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

  config.headers.Authorization = authToken ? `Bearer ${authToken}` : '';

  return config;
});

export default axiosInstance;

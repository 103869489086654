import { dec, evolve, inc, omit } from 'ramda';
import { CART_LOCAL_STORAGE_KEY } from '../config';

export function initializer(initialState = {}) {
  return (
    JSON.parse(localStorage.getItem(CART_LOCAL_STORAGE_KEY)) || initialState
  );
}

export function cartReducer(state, action) {
  if (action.type === 'CLEAR_CART') return {};

  var { product_id, size } = action.payload;
  var key = `${product_id}_${size}`;
  var item = state[key];

  switch (action.type) {
    case 'ADD_ITEM':
      return item == null
        ? { ...state, [key]: { product_id, size, qty: 1 } }
        : { ...state };

    case 'INCREASE_QUANTITY':
      return evolve({ [key]: evolve({ qty: inc }) }, state);

    case 'DECREASE_QUANTITY':
      return evolve({ [key]: evolve({ qty: dec }) }, state);

    case 'REMOVE_ITEM':
      return omit([key], state);

    default:
      return state;
  }
}

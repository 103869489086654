import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { addThousandSeparator } from '../helpers';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import ReportingService from '../services/reporting';

const BOX_STYLES = [
  {
    colors: { bgColor: '#ead9d5', color: '#9d5943' },
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#cbaba0"
          fillOpacity="1"
          d="M0,224L21.8,224C43.6,224,87,224,131,224C174.5,224,218,224,262,208C305.5,192,349,160,393,154.7C436.4,149,480,171,524,154.7C567.3,139,611,85,655,85.3C698.2,85,742,139,785,154.7C829.1,171,873,149,916,128C960,107,1004,85,1047,64C1090.9,43,1135,21,1178,16C1221.8,11,1265,21,1309,64C1352.7,107,1396,181,1418,218.7L1440,256L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
  {
    colors: { bgColor: '#cddec9', color: '#397223' },
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#8AAD7F"
          fillOpacity="1"
          d="M0,160L21.8,154.7C43.6,149,87,139,131,112C174.5,85,218,43,262,64C305.5,85,349,171,393,218.7C436.4,267,480,277,524,250.7C567.3,224,611,160,655,165.3C698.2,171,742,245,785,277.3C829.1,309,873,299,916,261.3C960,224,1004,160,1047,165.3C1090.9,171,1135,245,1178,277.3C1221.8,309,1265,299,1309,293.3C1352.7,288,1396,288,1418,288L1440,288L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
  {
    colors: { bgColor: '#eccaca', color: '#bc7070' },
    note: 'in stock',
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#cb7e7e"
          fillOpacity="1"
          d="M0,288L21.8,245.3C43.6,203,87,117,131,96C174.5,75,218,117,262,149.3C305.5,181,349,203,393,229.3C436.4,256,480,288,524,293.3C567.3,299,611,277,655,240C698.2,203,742,149,785,112C829.1,75,873,53,916,42.7C960,32,1004,32,1047,53.3C1090.9,75,1135,117,1178,112C1221.8,107,1265,53,1309,32C1352.7,11,1396,21,1418,26.7L1440,32L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
  {
    colors: { bgColor: '#d7c7c7', color: '#a07a80' },
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#8f5a62"
          fillOpacity="1"
          d="M0,288L21.8,288C43.6,288,87,288,131,266.7C174.5,245,218,203,262,165.3C305.5,128,349,96,393,85.3C436.4,75,480,85,524,117.3C567.3,149,611,203,655,208C698.2,213,742,171,785,149.3C829.1,128,873,128,916,133.3C960,139,1004,149,1047,170.7C1090.9,192,1135,224,1178,208C1221.8,192,1265,128,1309,128C1352.7,128,1396,192,1418,224L1440,256L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
  {
    colors: { bgColor: '#c0ccd6', color: '#6493b5' },
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#376791"
          fillOpacity="1"
          d="M0,288L21.8,288C43.6,288,87,288,131,266.7C174.5,245,218,203,262,165.3C305.5,128,349,96,393,85.3C436.4,75,480,85,524,117.3C567.3,149,611,203,655,208C698.2,213,742,171,785,149.3C829.1,128,873,128,916,133.3C960,139,1004,149,1047,170.7C1090.9,192,1135,224,1178,208C1221.8,192,1265,128,1309,128C1352.7,128,1396,192,1418,224L1440,256L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
  {
    colors: { bgColor: '#c9dbdb', color: '#7aa09c' },
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#629f98"
          fillOpacity="1"
          d="M0,224L21.8,224C43.6,224,87,224,131,192C174.5,160,218,96,262,90.7C305.5,85,349,139,393,170.7C436.4,203,480,213,524,224C567.3,235,611,245,655,240C698.2,235,742,213,785,208C829.1,203,873,213,916,197.3C960,181,1004,139,1047,144C1090.9,149,1135,203,1178,234.7C1221.8,267,1265,277,1309,256C1352.7,235,1396,181,1418,154.7L1440,128L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
  {
    colors: { bgColor: '#ead9d5', color: '#9d5943' },
    Wave: () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#cbaba0"
          fillOpacity="1"
          d="M0,224L21.8,224C43.6,224,87,224,131,224C174.5,224,218,224,262,208C305.5,192,349,160,393,154.7C436.4,149,480,171,524,154.7C567.3,139,611,85,655,85.3C698.2,85,742,139,785,154.7C829.1,171,873,149,916,128C960,107,1004,85,1047,64C1090.9,43,1135,21,1178,16C1221.8,11,1265,21,1309,64C1352.7,107,1396,181,1418,218.7L1440,256L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        />
      </svg>
    ),
  },
];

var Title = styled.h1`
  font-family: 'Reem Kufi', sans-serif;
  text-align: center;
  margin-top: 15px;
  font-size: 22px;
  color: #e28282;
  text-shadow: 0 2px 2px #cdcdcd;
`;

var Container = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

var StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 175px;
  height: 125px;
  border-radius: 5px;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  font-family: 'Reem Kufi', sans-serif;
  padding-top: 15px;
  position: relative;

  p {
    text-align: center;
    font-size: 15px;
  }

  p.amount {
    font-size: 18px;
  }

  svg {
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

function Reports() {
  var [reportItems, setReportItems] = useState(null);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);

    ReportingService.getAnalytics()
      .then(response => {
        return [
          { label: 'Product count', amount: response.product_count },
          { label: 'Order count', amount: response.order_count },
          {
            label: 'Invested amount',
            amount: response.total_product_price,
            note: 'in stock',
          },
          {
            label: 'Invested amount',
            amount: response.total_product_price + response.total_sub_total,
            note: 'in stock + sub total',
          },
          { label: 'Total subtotal', amount: response.total_sub_total },
          {
            label: 'Shipping fee',
            amount: response.total_shipping_fee,
            note: 'total',
          },
          {
            label: 'Remaining amount',
            amount: response.total_remaining_amount,
            note: 'ရဖို့ကျန်သေးတဲ့ဟာ',
          },
        ];
      })
      .then(setReportItems)
      .catch(() => {
        setError("Something's wrong while loading analytics data!");
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Title>Site analytics</Title>

      {loading && <Loader marginTop={'30px'} />}

      {!loading && error && (
        <Alert type={'error'} message={error} marginTop={'30px'} />
      )}

      {!loading && reportItems && (
        <Container>
          {reportItems.map((item, idx) => {
            var { Wave } = BOX_STYLES[idx];

            return (
              <StyledItem {...BOX_STYLES[idx].colors} key={idx}>
                <p>{item.label}</p>
                <p className="amount">{addThousandSeparator(item.amount)}</p>
                {item.note && <p>{item.note}</p>}

                {Wave && <Wave />}
              </StyledItem>
            );
          })}
        </Container>
      )}
    </>
  );
}

export default Reports;

import axios from '../axios';

const API_URL = process.env.REACT_APP_API_URL;

var OrderService = {
  createOrder(payload) {
    return axios
      .post(`${API_URL}/orders`, { ...payload })
      .then(response => response.data);
  },

  getOrders({
    destination,
    delivered,
    limit = 10,
    skip = 0,
    start_date,
    end_date,
    search,
  }) {
    var query = {};

    if (search) {
      query = { ...query, search };
    }

    if (destination) {
      query = { ...query, destination };
    }

    if (typeof delivered === 'boolean') {
      query = { ...query, delivered };
    }

    if (start_date && end_date) {
      query = { ...query, start_date, end_date };
    }

    return axios
      .get(`${API_URL}/orders`, {
        params: { ...query, limit, skip },
      })
      .then(response => response.data);
  },

  getOrder(_id, { withStockInfo = false } = {}) {
    return axios
      .get(`${API_URL}/orders/${_id}`, {
        params: {
          with_stock_info: withStockInfo,
        },
      })
      .then(response => response.data);
  },

  updateOrderStatus(_id, { delivered }) {
    return axios
      .patch(`${API_URL}/orders/${_id}`, { delivered })
      .then(response => response.data);
  },

  updateTranxImage(_id, { imageUrl }) {
    return axios
      .patch(`${API_URL}/orders/${_id}`, { transaction_image: imageUrl })
      .then(response => response.data);
  },

  updateOrder(_id, { update }) {
    return axios
      .put(`${API_URL}/orders/${_id}`, {
        ...update,
      })
      .then(response => response.data);
  },

  deleteOrder(_id) {
    return axios.delete(`${API_URL}/orders/${_id}`);
  },
};

export default OrderService;

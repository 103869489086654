import styled from 'styled-components';
import './Loader.css';

var Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props['marginTop'] || '10vh'};
`;

function Loader(props) {
  return (
    <Wrapper {...props}>
      <div className="lds-default">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </Wrapper>
  );
}

export default Loader;

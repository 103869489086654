import axios from '../axios';

const API_URL = process.env.REACT_APP_API_URL;

var ReportingService = {
  getAnalytics() {
    return axios
      .get(`${API_URL}/reportings/analytics`)
      .then(response => response.data);
  },

  getSaleAnalysis(year, month) {
    return axios
      .get(`${API_URL}/reportings/sale-analysis?year=${year}&month=${month}`)
      .then(response => response.data);
  },

  getProductAnalysis() {
    return axios
      .get(`${API_URL}/reportings/sold-products`)
      .then(response => response.data);
  },
};

export default ReportingService;

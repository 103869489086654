import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import Modal from '../components/Modal';

import ProductService from '../services/stocks';

var Wrapper = styled.div`
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  max-width: 500px;

  @media (max-width: 768px) {
    max-width: 75vw;
  }

  @media (max-width: 425px) {
    max-width: 80vw;
  }

  @media (max-width: 320px) {
    max-width: 90vw;
  }
`;

var Image = styled.img`
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
`;

var Name = styled.p`
  font-family: 'Rokkitt', serif;
  font-size: 30px;
`;

var Info = styled.p`
  font-size: 17px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  ion-icon {
    margin-right: 5px;
    color: goldenrod;
  }
`;

var InStockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

var InStockItem = styled.div`
  display: flex;
  margin-right: 5px;
  margin-top: 10px;

  p {
    background-color: ${props => (props.showWarning ? '#ffeaea' : 'white')};
    font-family: 'Rokkitt', serif;
    font-size: 17px;
    padding: 6px;
    border: 1px solid #f3bcbc;
  }

  p:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  p:nth-child(2) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .label {
    color: #919191;
  }
`;

var Actions = styled.div`
  margin-top: 20px;
  display: flex;

  button {
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    font-family: 'Reem Kufi', sans-serif;

    ion-icon {
      margin-left: 3px;
    }
  }

  > button:nth-child(1) {
    background-color: rgba(6, 91, 157, 0.57);

    &:hover {
      background-color: rgba(4, 112, 195, 0.57);
    }
  }

  > button:nth-child(2) {
    margin-left: 10px;
    background-color: #e5626a;

    &:hover {
      background-color: #fa7d85;
    }
  }
`;

var ConfirmationBox = styled.div`
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: 'Reem Kufi', sans-serif;
    font-size: 20px;
    color: #e72828e0;
  }

  div {
    margin-top: 20px;
    display: flex;

    button {
      border-radius: 5px;
      font-size: 15px;
      font-family: 'Reem Kufi', sans-serif;
      padding: 8px 12px;
      border: none;
      color: white;
    }

    button:nth-child(1) {
      margin-right: 10px;
      background-color: darkgrey;
    }

    button:nth-child(2) {
      background-color: #de7c7c;
    }
  }
`;

function StockDetail() {
  var { _id } = useParams();
  var navigate = useNavigate();

  var [product, setProduct] = useState(null);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    ProductService.getProductById(_id)
      .then(setProduct)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [_id]);

  var [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);

  function onStockRemove(stockId = _id) {
    setLoading(true);

    ProductService.removeProduct(stockId)
      .then(() => navigate('/stocks'))
      .catch(error => {
        setLoading(false);
        setError(error);
      });
  }

  function onStockEdit(stockId = _id) {
    if (stockId) {
      navigate(`/stocks/${_id}/edit`);
    }
  }

  // I'm lazy, so for now instead of refactor, I just append _
  if (loading) return <Loader marginTop={'15vh'} />;

  if (error)
    return (
      <Alert
        message={error.message || "Something's wrong while loading stock data!"}
        type={'error'}
      />
    );

  if (product == null) {
    return (
      <Alert
        message={'No data found. Maybe stock item was removed! : ('}
        type={'success'}
      />
    );
  } else {
    let { image, name, price, in_stock = [] } = product;

    return (
      <>
        <Wrapper>
          <Image src={image} alt="Stock image" />
          <Name>{name}</Name>
          <Info>
            <ion-icon name="logo-usd" /> {price} MMK
          </Info>
          <InStockWrapper>
            {in_stock.map(item => {
              return (
                <InStockItem key={item._id} showWarning={item.quantity <= 1}>
                  <p>
                    <span className={'label'}>Size:</span> {item.size}
                  </p>
                  <p>
                    <span className={'label'}>Quantity:</span> {item.quantity}
                  </p>
                </InStockItem>
              );
            })}
          </InStockWrapper>
          <Actions>
            <button onClick={() => onStockEdit(_id)}>
              Edit
              <ion-icon name="create-outline" />
            </button>
            <button onClick={() => setDeleteConfirmationDialogOpen(true)}>
              Remove <ion-icon name="trash-outline" />
            </button>
          </Actions>
        </Wrapper>
        <Modal
          open={deleteConfirmationDialogOpen}
          onClose={() => setDeleteConfirmationDialogOpen(false)}>
          <ConfirmationBox>
            <h2>Are you sure to delete this product?</h2>
            <div>
              <button onClick={() => setDeleteConfirmationDialogOpen(false)}>
                Nope.
              </button>
              <button onClick={() => onStockRemove(_id)}>Yes, please!</button>
            </div>
          </ConfirmationBox>
        </Modal>
      </>
    );
  }
}

export default StockDetail;

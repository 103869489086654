import { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import Pagination from '../components/Pagination';
import OrderItemPreview from '../components/OrderItemPreview';

import OrderService from '../services/order';

var GlobalStyles = createGlobalStyle`
  body {
    background-color: #fff1f1;
  }
`;

var Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
`;

var Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-family: 'Reem Kufi', sans-serif;
    font-weight: normal;
    font-size: 27px;
    color: #d45050;
    text-shadow: 0 3px 3px #cdcdcd;

    span {
      font-size: 18px;
      color: white;
      background-color: #e28c8c;
      padding: 0 6px;
      border-radius: 5px;
    }
  }

  select {
    font-family: 'Reem Kufi', sans-serif;
    font-size: 15px;
    padding: 5px 13px 5px 20px;
    border-radius: 7px;
    background-color: white;
    box-shadow: 3px 3px 3px 0 #e5e1e1;
    border: 1px solid #f5cbcb;
    color: #e97e7e;
    font-weight: bold;
  }
`;

var Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
`;

const ITEMS_PER_PAGE = 20;

function NewOrders() {
  var [searchParams, setSearchParams] = useSearchParams();
  var [orders, setOrders] = useState([]);
  var [totalCount, setTotalCount] = useState(0);
  var [currentPage, setCurrentPage] = useState(1);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState('');

  var destination = searchParams.get('destination');
  destination = destination ? destination.toUpperCase() : 'YANGON';

  useEffect(() => {
    setLoading(true);

    OrderService.getOrders({
      destination,
      delivered: false,
      limit: ITEMS_PER_PAGE,
      skip: (currentPage - 1) * ITEMS_PER_PAGE,
    })
      .then(response => {
        setOrders(response.data);
        setTotalCount(response.count);
      })
      .catch(() => setError("Something's wrong while loading new orders!"))
      .finally(() => setLoading(false));
  }, [destination, currentPage]);

  return (
    <>
      <GlobalStyles />
      <Container>
        <Header>
          <h1>
            New Orders <span>{totalCount}</span>
          </h1>
          <select
            value={destination}
            onChange={event => {
              event.preventDefault();
              setSearchParams({ destination: event.target.value });
            }}>
            <option value="YANGON">YGN</option>
            <option value="MANDALAY">MDY</option>
            <option value="ROYAL">ROYAL</option>
            <option value="PRE_ORDER">Pre Order</option>
          </select>
        </Header>
        <>
          {loading && <Loader marginTop={'100px'} />}

          {!loading && error && <Alert message={error} type={'error'} />}

          {!loading && totalCount === 0 && (
            <Alert type={'success'} message={'No new orders right now!'} />
          )}

          {!loading && totalCount > 0 && (
            <>
              <Content>
                {orders.map(item => (
                  <OrderItemPreview item={item} key={item._id} />
                ))}
              </Content>

              <Pagination
                totalItems={totalCount}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                itemsPerPage={ITEMS_PER_PAGE}
              />
            </>
          )}
        </>
      </Container>
    </>
  );
}

export default NewOrders;

import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './Footer';

var Main = styled.main`
  margin-bottom: 100px;
`;

function Layout() {
  // TODO :: modify header

  return (
    <>
      {/*<Header />*/}
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
}

export default Layout;

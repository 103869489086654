import { createContext, useEffect, useReducer } from 'react';
import { curry } from 'ramda';

import { cartReducer, initializer } from '../reducers/cart';
import { CART_LOCAL_STORAGE_KEY } from '../config';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  var [cart, dispatch] = useReducer(cartReducer, {}, initializer);
  var dispatcher = curry((type, payload) => dispatch({ type, payload }));

  useEffect(() => {
    localStorage.setItem(CART_LOCAL_STORAGE_KEY, JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        addItem: dispatcher('ADD_ITEM'),
        increaseQuantity: dispatcher('INCREASE_QUANTITY'),
        decreaseQuantity: dispatcher('DECREASE_QUANTITY'),
        removeItem: dispatcher('REMOVE_ITEM'),
        clearCart: dispatcher('CLEAR_CART'),
        cartItemKeys: Object.keys(cart),
        cartItems: Object.values(cart),
        cartItemsCount: Object.keys(cart).length,
        product_ids: Object.values(cart).map(x => x.product_id),
      }}>
      {children}
    </CartContext.Provider>
  );
};

import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '../components/Loader';
import Alert from '../components/Alert';
import StockItem from '../components/StockItem';
import Pagination from '../components/Pagination';

import { stocksReducer } from '../reducers/stocks';
import productService from '../services/stocks';

var Wrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

var Items = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  overflow-y: auto;
`;

var Header = styled.div`
  font-size: 16px;
  font-family: 'Reem Kufi', sans-serif;
  display: flex;
  justify-content: ${props =>
    props.stockCount == null ? 'flex-end' : 'space-between'};
  margin: 10px 15px 10px 15px;
  align-items: center;

  p {
    span {
      padding: 2px 7px;
      display: inline-block;
      background-color: #e77e7e;
      border-radius: 4px;
      color: white;
      margin-left: 4px;
    }
  }
`;

var Button = styled.button`
  padding: 4px 6px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Reem Kufi', sans-serif;
  border-radius: 3px;
  border: none;
  color: white;
  background-color: #e77e7e;

  :hover {
    background-color: #ea6b6b;
  }

  ion-icon {
    margin-right: 3px;
  }
`;

var Search = styled.div`
  display: flex;
  margin-left: 10px;
  margin-bottom: 20px;

  input {
    padding: 5px;
    font-family: 'Reem Kufi', sans-serif;
    border: 1px solid #e28c8c;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  button {
    padding: 5px;
    border: 1px solid #e28c8c;
    background-color: #e28c8c;
    color: white;
    font-family: 'Reem Kufi', sans-serif;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

function StocksPageHeader({ stockCount }) {
  var navigate = useNavigate();
  var goTo = path => () => navigate(path);

  return (
    <>
      <Header stockCount={stockCount}>
        {stockCount != null && (
          <p>
            Total: <span>{stockCount}</span>
          </p>
        )}
        <Button onClick={goTo('/stocks/new')}>
          <ion-icon name="add-circle-outline" />
          New Product
        </Button>
      </Header>
    </>
  );
}

const ITEMS_PER_PAGE = 10;

function Stocks() {
  var [{ stocks, count, loading, error }, dispatchStocks] = useReducer(
    stocksReducer,
    {
      loading: false,
      error: null,
      stocks: [],
      count: 0,
    }
  );

  var [searchTerm, setSearchTerm] = useState('');
  var [currentPage, setCurrentPage] = useState(1);

  var loadData = () => {
    dispatchStocks({ type: 'INIT_LOADING' });

    productService
      .getProducts({
        limit: 10,
        skip: (currentPage - 1) * 10,
        search: searchTerm,
      })
      .then(response => {
        dispatchStocks({
          type: 'LOADING_SUCCESS',
          payload: response,
        });
      })
      .catch(error => {
        dispatchStocks({
          type: 'LOADING_FAIL',
          payload: {
            error: {
              message: error.message,
            },
          },
        });
      });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <Wrapper>
      <StocksPageHeader stockCount={count} />

      <Search>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={event => {
            event.preventDefault();
            setSearchTerm(event.target.value);
          }}
        />
        <button onClick={() => loadData()}>search</button>
      </Search>

      {/*initial loading, without any data*/}
      {loading && <Loader marginTop={'15vh'} />}

      {/*not loading, but when some error occurs, maybe network error*/}
      {!loading && error && (
        <Alert type={'error'} message={error.message} marginTop={'15vh'} />
      )}

      {!loading &&
        (stocks.length === 0 ? (
          <Alert
            type={'success'}
            message={'No data right now, please add new!'}
            marginTop={'15vh'}
          />
        ) : (
          <>
            <Items>
              {stocks.map(stock => (
                <StockItem key={stock._id} {...stock} />
              ))}
            </Items>
            <Pagination
              totalItems={count}
              currentPage={currentPage}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={page => {
                setCurrentPage(page);
              }}
            />
          </>
        ))}
    </Wrapper>
  );
}

export default Stocks;

import { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import Loader from '../components/Loader';
import Alert from '../components/Alert';

import { uploadImageToS3 } from '../services/image';
import ProductService from '../services/stocks';

var Title = styled.div`
  margin-top: 20px;
  text-align: center;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 22px;
  text-shadow: 0 4px 4px rgba(64, 64, 64, 0.25);
  color: #e5626a;
`;

var StyledForm = styled(Form)`
  margin: 15px auto;

  width: 500px;

  @media (max-width: 768px) {
    width: 75vw;
  }

  @media (max-width: 425px) {
    width: 85vw;
  }

  @media (max-width: 320px) {
    width: 95vw;
  }

  div.form-footer {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    button.add-stock-btn {
      padding: 5px 13px;
      border: none;
      border-radius: 4px;
      background-color: #ea6b72;
      color: white;
      font-family: 'Reem Kufi', sans-serif;
      display: flex;
      align-items: center;
      font-size: 15px;

      ion-icon {
        margin-left: 4px;
      }
    }
  }
`;

var StyledField = styled(Field)`
  margin-top: 10px;
  margin-bottom: 4px;
  padding: 6px 8px;
  border-radius: 4px;
  border: 2px solid #ffeaea;
  color: #e5626a;
  width: 100%;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 16px;

  &:focus,
  &:hover {
    border: 2px solid #eca8a8;
  }

  transition: border-color ease 0.45s;
`;

var StyledInStockFieldArray = styled(FieldArray)``;

var InStockRow = styled.div`
  display: flex;
  margin-top: 10px;
`;

var ErrorMessagesRow = styled.div`
  margin-top: 4px;
  display: flex;
  color: rgba(224, 34, 34, 0.88);

  p {
    &:before {
      content: '*';
    }

    width: 47%;
  }
`;

var SizeInput = styled(StyledField)`
  margin: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

var QuantityInput = styled(StyledField)`
  margin: 0;
  border-right: none;
  border-radius: 0;
`;

var IconBtn = styled.button`
  padding: 6px 8px;
  border: none;
  background-color: #ea6b72;
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;

  ion-icon {
    font-size: 1.1rem;
  }

  &:disabled {
    background-color: #e5969b;
  }
`;

var StockImage = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 4px;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  padding: 10px;

  div.choose-new-section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    background-color: rgba(67, 62, 62, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
      color: white;
      font-size: 1.5rem;
    }
  }
`;

var SubmitBtn = styled.button`
  margin-top: 15px;
  padding: 5px 10px;
  border: none;
  background-color: #e77e7e;
  font-size: 16px;
  font-family: 'Reem Kufi', sans-serif;
  border-radius: 3px;
  color: white;

  :hover {
    background-color: #ea6b6b;
  }
`;

var StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 4px;
  color: rgba(224, 34, 34, 0.88);

  &:before {
    content: '*';
  }
`;

var productSchema = yup.object({
  name: yup.string().required('Name is required!'),
  price: yup.number().required('Price is required!'),
  in_stock: yup.array().of(
    yup.object().shape({
      size: yup.string().required('Size is required'),
      quantity: yup.number().required('Quantity is required'),
    })
  ),
});

function StockEditForm() {
  var { _id } = useParams();
  var navigate = useNavigate();

  var [product, setProduct] = useState(null);
  var [loading, setLoading] = useState(false);
  var [error, setError] = useState(null);
  var [imgBlob, setImgBlob] = useState('');
  var imageInputRef = createRef();

  useEffect(() => {
    setLoading(true);

    ProductService.getProductById(_id)
      .then(setProduct)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [_id]);

  var initialValues = product
    ? {
        name: product.name,
        price: product.price,
        in_stock: product.in_stock.map(x => ({
          size: x.size,
          quantity: x.quantity,
        })),
        image: null,
      }
    : {
        name: '',
        price: '',
        image: null,
        in_stock: [{ size: '', quantity: '' }],
      };

  var goBackToPreviousPage = () => navigate(-1);

  var onFileChange = event => {
    var f = event.target.files[0];

    if (f) {
      setImgBlob(URL.createObjectURL(f));
    } else {
      setImgBlob('');
    }
  };

  var onSubmit = async values => {
    var imageUrl = product ? product.image : '';
    var newImage = values.image;

    if (newImage != null) {
      try {
        imageUrl = await uploadImageToS3({
          file: newImage,
          type: 'product',
        });
      } catch (e) {
        setError(new Error("Something's wrong while uploading image!"));
        return;
      }
    }

    setLoading(true);
    return ProductService.updateProduct(_id, {
      name: values.name,
      price: values.price,
      image: imageUrl,
      in_stock: values.in_stock,
    })
      .then(goBackToPreviousPage)
      .catch(error => {
        setLoading(false);
        setError(error);
      });
  };

  return (
    <div>
      <Title>Edit Product</Title>

      {loading && <Loader marginTop={'100px'} />}
      {!loading && error && <Alert message={error.message} type={'error'} />}

      {!loading && product && (
        <Formik
          initialValues={initialValues}
          validationSchema={productSchema}
          onSubmit={onSubmit}>
          {({ values, setFieldValue, isSubmitting }) => (
            <StyledForm>
              <StyledField type="text" name="name" placeholder="Name" />
              <StyledErrorMessage name={'name'} component={'p'} />

              <StyledField type="number" name="price" placeholder="Price" />
              <StyledErrorMessage name={'price'} component={'p'} />

              <StyledInStockFieldArray name="in_stock">
                {({ remove }) =>
                  values.in_stock.length > 0 &&
                  values.in_stock.map((item, index) => (
                    <div key={index}>
                      <InStockRow>
                        <SizeInput
                          name={`in_stock.${index}.size`}
                          type="text"
                          placeholder="Size"
                        />
                        <QuantityInput
                          name={`in_stock.${index}.quantity`}
                          type="number"
                          placeholder="Quantity"
                        />

                        <IconBtn
                          type={'button'}
                          onClick={() => remove(index)}
                          disabled={values.in_stock.length === 1}>
                          <ion-icon name="trash-outline" />
                        </IconBtn>
                      </InStockRow>
                      <ErrorMessagesRow>
                        <ErrorMessage
                          name={`in_stock.${index}.size`}
                          component="p"
                        />

                        <ErrorMessage
                          name={`in_stock.${index}.quantity`}
                          component="p"
                        />
                      </ErrorMessagesRow>
                    </div>
                  ))
                }
              </StyledInStockFieldArray>

              <div className={'form-footer'}>
                <StockImage imageUrl={imgBlob || product.image}>
                  <div
                    className={'choose-new-section'}
                    onClick={() => imageInputRef.current.click()}>
                    <ion-icon name="image-outline" />
                  </div>
                  <input
                    ref={imageInputRef}
                    name={'image'}
                    type={'file'}
                    hidden
                    onChange={event => {
                      setFieldValue('image', event.target.files[0]);
                      onFileChange(event);
                    }}
                  />
                </StockImage>

                <button
                  className={'add-stock-btn'}
                  type={'button'}
                  onClick={() =>
                    setFieldValue('in_stock', [
                      ...values.in_stock,
                      { size: '', quantity: '' },
                    ])
                  }>
                  Add stock
                  <ion-icon name="add-outline" />
                </button>
              </div>
              <SubmitBtn disabled={isSubmitting} type={'submit'}>
                Submit
              </SubmitBtn>
            </StyledForm>
          )}
        </Formik>
      )}
    </div>
  );
}

export default StockEditForm;
